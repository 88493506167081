import { OptionGroup } from '@/components/form/rhf-combobox/grouped-options';
import { SelectOption } from '@/components/form/rhf-combobox/select-options';
import { cn } from '@ui/lib/utils';
import { Check } from 'lucide-react';
import { ControllerRenderProps, FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

export function renderCheck<TFieldValues extends FieldValues>(
  value: PathValue<TFieldValues, Path<TFieldValues>>,
  field: ControllerRenderProps<TFieldValues>,
) {
  let isValueMatching = false;

  if (!field) {
    return null;
  }

  if (Array.isArray(field?.value)) {
    isValueMatching = field.value?.includes(value);
  } else {
    isValueMatching = value === field.value;
  }

  return <Check className={cn('mr-2 h-4 w-4', isValueMatching ? 'opacity-100' : 'opacity-0')} />;
}

export function buildOptions<TFieldValues extends FieldValues>(
  name: Path<TFieldValues>,
  options: SelectOption<TFieldValues>[] | OptionGroup<TFieldValues>[],
  form: UseFormReturn<TFieldValues>,
): SelectOption<TFieldValues>[] | OptionGroup<TFieldValues>[] {
  const fieldValue = form.getValues(name);
  if (isGrouped(options)) {
    return buildGroupedOptions(options, fieldValue);
  } else {
    return buildSingleOptions(options, fieldValue);
  }
}

function buildGroupedOptions<TFieldValues>(
  options: OptionGroup<TFieldValues>[],
  fieldValue: PathValue<TFieldValues, Path<TFieldValues>>,
): OptionGroup<TFieldValues>[] {
  const uniqueOptions = removeDuplicates(options.flatMap((group) => group.options));
  return [...options, { label: 'Custom Options', options: buildSingleOptions(uniqueOptions, fieldValue) }];
}

function buildSingleOptions<TFieldValues>(
  options: SelectOption<TFieldValues>[],
  fieldValue: PathValue<TFieldValues, Path<TFieldValues>>,
): SelectOption<TFieldValues>[] {
  let newOptions;
  if (Array.isArray(fieldValue)) {
    newOptions = fieldValue.map((v) => ({ label: v, value: v }));
  } else {
    newOptions = [{ label: fieldValue, value: fieldValue }];
  }
  const combinedOptions = [...options, ...newOptions];
  return removeDuplicates(combinedOptions);
}

/** Remove duplicate options */
function removeDuplicates<TFieldValues>(options: SelectOption<TFieldValues>[]): SelectOption<TFieldValues>[] {
  const seen = new Set();
  return options.filter((option) => {
    const duplicate = seen.has(option.value);
    seen.add(option.value);
    return !duplicate;
  });
}

/** Determine if the options are grouped or not */
export function isGrouped<TFieldValues extends FieldValues>(
  options: SelectOption<TFieldValues>[] | OptionGroup<TFieldValues>[],
): options is OptionGroup<TFieldValues>[] {
  return options.length > 0 && 'options' in options[0];
}

/**  Flattens an array of options, which can be either SelectOption or OptionGroup, into an array of SelectOption. */
export function flattenOptions<TFieldValues>(
  options: (SelectOption<TFieldValues> | OptionGroup<TFieldValues>)[]
): SelectOption<TFieldValues>[] {
  return options.flatMap(option => {
    if ('options' in option) {
      // This is an OptionGroup
      return option.options;
    }
    // This is a SelectOption
    return [option];
  });
}