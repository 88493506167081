import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { DataTableRowActions } from '@/components/data-table-row-actions';
import { Restaurant } from '@repo/types';
import { ColumnDef } from '@tanstack/react-table';
import {Switch, toast } from '@ui/components';
import {Settings, Trash} from 'lucide-react';
import { useState } from 'react';
import { Event } from '@repo/types';
import {useEventUpdate} from "@/hooks/useEventUpdate";
import {useEventDelete} from "@/hooks/useEventDelete";
import {ConfirmationDialog} from "@/components/confirmation-dialog";

interface EventListProps {
    restaurant: Restaurant;
    events: Event[];
}
export const EventList = ({ restaurant, events }: EventListProps) => {
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const deleteEventMutation = useEventDelete(restaurant.id);

    const handleConfirmDelete = async () => {
        await deleteEventMutation.mutateAsync(
            {
                data: {
                    id: selectedEvent?.id || '',
                },
            },
            {
                onSuccess: () => {
                    toast({title: 'Removed event', variant: 'success'});
                    setSelectedEvent(null);
                },
                onError: () => {
                    toast({title: 'Failed to remove event', variant: 'destructive'});
                },
            },
        );
    };

    const getColumns: (restaurant: Restaurant ) => ColumnDef<Event>[] = (restaurant) => [
        {
            accessorKey: 'name',
            header: ({column}) => <DataTableColumnHeader column={column} title="Name"/>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            id: 'actions',
            cell: ({row}) => <DataTableRowActions actions={[
                {icon: Trash, to: location.pathname, onClick: () => setSelectedEvent(row.original as Event)},
                {icon: Settings, to: row.original.id}]}/>,
        },
        {
            accessorKey: 'isActive',
            header: () => null,
            cell: ({row}) => (
                <div className="flex items-center justify-center">
                    <EventStatusSwitch
                        restaurant={restaurant}
                        eventId={row.original.id}
                        isActive={row.getValue('isActive')}
                    />
                </div>
            ),
        },
    ];
    return (
        <>
        <div className="px-4">
            <DataTable
                columns={getColumns(restaurant)}
                data={events}
                searchFilters={[{placeholder: 'Filter by Event Name...', columnId: 'name'}]}
            />
        </div>

    <ConfirmationDialog
        open={!!selectedEvent}
        title={`Delete '${selectedEvent?.name}' event`}
        description="Are you sure you want to remove this model from the restaurant?"
        cancelLabel="Cancel"
        continueLabel="Delete"
        onCancel={() => setSelectedEvent(null)}
        onContinue={handleConfirmDelete}
    />
        </>
);


}
const EventStatusSwitch = ({
                             restaurant,
                             eventId,
                             isActive,
                         }: {
    restaurant: Restaurant;
    eventId: string;
    isActive: boolean;
}) => {
    const [isChecked, setIsChecked] = useState(isActive);

    const updateEvent = useEventUpdate(restaurant.id);

    const handleCheckedChange = (checked: boolean) => {
        setIsChecked(checked);

        updateEvent.mutateAsync({
            data: {
                id: eventId,
                isActive: checked,
            },
        });
    };

    return (
        <Switch checked={isChecked} onCheckedChange={handleCheckedChange} disabled={updateEvent.isPending} />
    );
};

