import { ToastAction } from "@ui/components";
import { Toast } from "@ui/components/ui/use-toast";
import { FieldErrors, FieldValues } from "react-hook-form";

export const invalidDisplay: Toast = {
  variant: "destructive",
  title: "Error",
  description: "You must turn on at least one of 'Table, Checkout, or Email' displays",
};

export const imageUploadSuccess: Toast = {
  variant: "success",
  title: "Image(s) uploaded!",
};

export const imageUploadFail: Toast = {
  variant: "destructive",
  title: "Uh oh! Something went wrong",
  description: "There was a problem uploading your images",
  action: <ToastAction altText='Try again'>Try again</ToastAction>,
};

export const policyCreateSuccess = (description: string): Toast => ({
  variant: "success",
  title: "New policy created",
  description,
});

export const policyCreateFail: Toast = {
  variant: "destructive",
  title: "Error",
  description: "Failed to create new policy",
};

export const policyUpdateSuccess = (description: string): Toast => ({
  variant: "success",
  title: "Policy updated",
  description,
});

export const policyUpdateFail = (description: string): Toast => ({
  variant: "destructive",
  title: "Error",
  description,
});

export const generalUpdateSuccess = (title: string): Toast => ({
  variant: "success",
  title,
});

export const generalUpdateFail: Toast = {
  variant: "destructive",
  title: "Uh oh! Something went wrong",
  description: "There was a problem with saving your changes",
  action: <ToastAction altText='Try again'>Try again</ToastAction>,
};

export const policyDeleteSuccess: Toast = {
  variant: "success",
  title: "Policy deleted",
};

export const policyDeleteFail: Toast = {
  variant: "destructive",
  title: "Failed to delete policy",
};

export const formValidationToast = ({
  title,
  errors,
  fixLabel,
  fixAction,
}: {
  title: string;
  errors: FieldErrors<FieldValues>;
  fixLabel: string;
  fixAction: () => void;
}): Toast => ({
  variant: "destructive",
  title,
  description: (
    <ul>
      {Object.values(errors).map(
        (error, index) =>
          error && (
            <li key={`${index}--${error.message}`} className='list-disc list-inside list-item'>
              {error.message?.toString()}
            </li>
          )
      )}
    </ul>
  ),
  action: (
    <ToastAction altText={fixLabel} onClick={fixAction}>
      Fix
    </ToastAction>
  ),
});
