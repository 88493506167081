import TableService from '@/services/table.service';
import { Table } from '@repo/types';
import { useQuery } from '@tanstack/react-query';

const useTable = ({ restaurantId, tableId }: { restaurantId: string; tableId: string }) => {
  return useQuery<Table, Error>({
    enabled: !!restaurantId && !!tableId,
    queryKey: ['table', tableId],
    queryFn: () => TableService.findOne({restaurantId, tableId}),
    select: (data) => transformData(data),
  });
};

export { useTable };

const transformData = (table: Table): Table => {

  // Filter out any empty strings in the attributes
  const filteredAttributes = {
    ambiance: table?.attributes?.ambiance?.filter((val) => val),
    view: table?.attributes?.view?.filter((val) => val),
    spacial: table?.attributes?.spacial?.filter((val) => val),
  };

  return {
    ...table,
    attributes: filteredAttributes,
  };
};
