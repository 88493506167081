import { forwardRef, HTMLAttributes, ReactNode } from "react";

export interface TItem {
  id: string;
  content: ReactNode;
}

type ItemProps<T> = {
  item: T & TItem;
  isOpacityEnabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Item = forwardRef<HTMLDivElement, ItemProps<any>>(({ item, isOpacityEnabled, style, ...props }, ref) => {
  return (
    <div
      ref={ref}
      style={{ ...style, opacity: isOpacityEnabled ? 0.5 : 1 }}
      {...props}
    >
      {item.content}
    </div>
  );
});

export default Item;
