import { ConfirmationDialog } from '@/components/confirmation-dialog';
import { CopyToClipboardButton } from '@/components/copy-to-clipboard-button';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { DataTableRowActions } from '@/components/data-table-row-actions';
import { useTables } from '@/hooks';
import { useModelUpdate } from '@/hooks/useModelUpdate';
import { useRestaurantUpdate } from '@/hooks/useRestaurantUpdate';
import { MatterportScan, Restaurant } from '@repo/types';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Badge, Switch, useToast } from '@ui/components';
import { Settings, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ModelListProps {
  restaurant: Restaurant;
  models: MatterportScan[];
}
export const ModelList = ({ restaurant, models }: ModelListProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [selectedModel, setSelectedModel] = useState<MatterportScan | null>(null);

  const updateRestaurantMutation = useRestaurantUpdate(restaurant.id);

  const handleDeleteClick = (model: MatterportScan) => {
    setSelectedModel(model);
  };

  const handleConfirmDelete = async () => {
    const existingModels = restaurant.layouts || [];
    const updatedModels = existingModels.filter((model) => model.model !== selectedModel?.model);
    await updateRestaurantMutation.mutateAsync(
      {
        id: restaurant.id,
        data: {
          layouts: updatedModels,
        },
      },
      {
        onSuccess: () => {
          toast({ title: 'Removed model', variant: 'success' });
          setSelectedModel(null);
          navigate(`/${restaurant.slug}/matterport-models`);
        },
        onError: () => {
          toast({ title: 'Failed to remove model', variant: 'destructive' });
        },
      },
    );
  };

  const columns: ColumnDef<MatterportScan>[] = [
    {
      accessorKey: 'displayName',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Display Name" />,
      cell: ({ row }) => {
        return (
          <span className="flex items-center gap-2">
            {row.getValue('displayName')} {row.original.isDefault ? <Badge>Default</Badge> : ''}
          </span>
        );
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'model',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Model ID" />,
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <span>{row.getValue('model')}</span>
          <div className="w-1">
            <CopyToClipboardButton text={row.getValue('model')} />
          </div>
        </div>
      ),
      enableSorting: false,
      enableHiding: true,
    },
    {
      id: 'pins',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Pins" />,
      cell: ({ row }) => <PinCount restaurant={restaurant} modelId={row.getValue('model')} />,
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'actions',
      cell: ({ row }) => (
        <DataTableRowActions
          actions={[
            { icon: Trash2, to: location.pathname, onClick: () => handleDeleteClick(row.original) },
            { icon: Settings, to: row.original.model },
          ]}
        />
      ),
    },
    {
      accessorKey: 'isActive',
      header: () => null,
      cell: ({ row }) => (
        <div className="flex items-center justify-center">
          <ModelStatusSwitch
            restaurant={restaurant}
            modelId={row.getValue('model')}
            isActive={row.getValue('isActive')}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="px-4">
        <DataTable
          columns={columns}
          data={models}
          searchFilters={[{ placeholder: 'Filter by Display Name...', columnId: 'displayName' }]}
        />
      </div>

      <ConfirmationDialog
        open={!!selectedModel}
        title={`Delete '${selectedModel?.displayName}' model`}
        description="Are you sure you want to remove this model from the restaurant?"
        cancelLabel="Cancel"
        continueLabel="Delete"
        onCancel={() => setSelectedModel(null)}
        onContinue={handleConfirmDelete}
      />
    </>
  );
};

const PinCount = ({ restaurant, modelId }: { restaurant: Restaurant; modelId: string }) => {
  const tablesQuery = useTables({ restaurantId: restaurant.id });
  const count = tablesQuery.data?.filter((table) => table.model === modelId).length;

  return tablesQuery.isLoading ? null : <span>{count}</span>;
};

const ModelStatusSwitch = ({
  restaurant,
  modelId,
  isActive,
}: {
  restaurant: Restaurant;
  modelId: string;
  isActive: boolean;
}) => {
  const [isChecked, setIsChecked] = useState(isActive);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const previousModels =
    queryClient.getQueryData<Restaurant>(['getRestaurantBySlug', restaurant.slug])?.layouts || [];

  const updateModel = useModelUpdate({
    onMutate: async (variables) => {
      // Optimistically update the UI
      queryClient.setQueryData(['getRestaurantBySlug', restaurant.slug], (old: Restaurant) => {
        const updatedLayouts = old.layouts.map((layout) =>
          layout.model === variables.modelId ? { ...layout, isActive: variables.data.isActive } : layout,
        );
        return { ...old, layouts: updatedLayouts };
      });
    },
    onError: (error, variables, _context) => {
      // Rollback to the previous state
      queryClient.setQueryData(['getRestaurantBySlug', restaurant.slug], previousModels);
      // Rollback local state
      setIsChecked(!variables.data.isActive);
      console.error(error);
      toast({
        title: 'Failed to update model status',
        variant: 'destructive',
      });
    },
    onSettled: () => {
      // Invalidate the query to ensure fresh data
      queryClient.invalidateQueries({ queryKey: ['getRestaurantBySlug', restaurant.slug] });
    },
  });

  const handleCheckedChange = (checked: boolean) => {
    setIsChecked(checked);

    updateModel.mutateAsync({
      restaurantId: restaurant.id,
      modelId,
      data: {
        isActive: checked,
      },
    });
  };

  return (
    <Switch checked={isChecked} onCheckedChange={handleCheckedChange} disabled={updateModel.isPending} />
  );
};
