import { capitalized } from "@/helpers/common.helper";
import { TableType } from "@repo/types";

export const FeeUnitOptions = [
  ...Object.values(TableType).map((option) => ({
    label: capitalized(option),
    value: option,
  })),
  { label: "Guest", value: "guest" },
];
