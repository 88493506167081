import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import { BasePolicyCard } from '@/components/policies/cards';
import {
  BookingGracePeriodFormData,
  bookingGracePeriodFormSchema,
} from '@/components/policies/cards/schemas';
import { PolicyDefaultDisclaimerMap } from '@/constants/policies.constants';
import {
  getDayOfWeekCondition,
  getDisplaysArray,
  getHasItemCondition,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  getInitialTitle,
} from '@/helpers/form.helper';
import { DisplayLocation, Policy, PolicyType, UpdatePolicyRequest } from '@repo/types';
import * as React from 'react';

interface BookingGracePeriodCardProps {
  policy: Policy;
  maxWeight: number;
}

const BookingGracePeriodCard: React.FC<BookingGracePeriodCardProps> = ({ policy, maxWeight }) => {
  const formDefaultValues: BookingGracePeriodFormData = {
    title: getInitialTitle(policy, 'Grace Period'),
    disclaimer: getInitialDisclaimer(policy, PolicyDefaultDisclaimerMap[policy.type as PolicyType] || ''),
    displayTable: policy.displays.some((display) => display.location === DisplayLocation.TABLE),
    displayEmail: policy.displays.some((display) => display.location === DisplayLocation.EMAIL),
    displayCheckout: policy.displays.some((display) => display.location === DisplayLocation.CHECKOUT),
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
    },
  };

  const getPolicyCreateData = (formData: BookingGracePeriodFormData) => ({
    name: PolicyType.GRACE_PERIOD,
    description: PolicyType.GRACE_PERIOD,
    type: PolicyType.GRACE_PERIOD,
    displays: getDisplaysArray(formData),
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getHasItemCondition(policy),
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: BookingGracePeriodFormData): UpdatePolicyRequest => ({
    ...policy,
    id: policy.id,
    displays: getDisplaysArray(formData),
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getHasItemCondition(policy),
      },
    },
  });

  return (
    <BasePolicyCard<BookingGracePeriodFormData>
      formSchema={bookingGracePeriodFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={PolicyType.GRACE_PERIOD}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <PolicyDayOfWeekSelector form={form} />

          <RHFTextInput form={form} name="title" label="Title" canCopyToClipboard />
          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" />

          <div className="grid gap-4 sm:grid-cols-3">
            <RHFSwitch
              form={form}
              name="displayTable"
              label="Table"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayCheckout"
              label="Checkout"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayEmail"
              label="Email"
              className="justify-between p-3 border rounded-md"
            />
          </div>
        </>
      )}
    </BasePolicyCard>
  );
};

export default BookingGracePeriodCard;
