import { policyDeleteFail, policyDeleteSuccess } from "@/components/toasts";
import PolicyService from "@/services/policy.service";
import { Policy } from "@repo/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";

const useTablePolicyDelete = (tableId?: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Policy, Error, { restaurantId: string; tableId: string; policyId: string }>({
    mutationKey: ["deleteTablePolicy", tableId],
    mutationFn: ({ restaurantId, tableId, policyId }) => PolicyService.deleteTablePolicy(restaurantId, tableId, policyId),
    onSuccess: () => {
      toast(policyDeleteSuccess);
      return queryClient.invalidateQueries({ queryKey: ["tablePolicies", tableId] });
    },
    onError: (error) => {
      console.error(error);
      toast(policyDeleteFail);
    },
  });
};

export { useTablePolicyDelete };
