import Item, { TItem } from "@/components/draggable/item";
import SortableItem from "@/components/draggable/sortable-item";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Card, CardContent } from "@ui/components";
import { useState } from "react";
import { createPortal } from "react-dom";

interface DraggableProps<T> {
  items: Array<T & TItem>;
  onDragEnd: (items: Array<T & TItem>) => void;
}

export default function Draggable<T>({ items, onDragEnd }: DraggableProps<T>) {
  const [activeItem, setActiveItem] = useState<T | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // the distance in pixels, by which the pointer needs to be moved before a drag start event is emitted (this allows nested buttons to be clickable)
        distance: 3,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveItem(items.find((item) => item.id === active.id) || null);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeIndex = items.findIndex((item) => item.id === active.id);
    const overIndex = items.findIndex((item) => item.id === over.id);

    if (activeIndex !== overIndex) {
      const newItems = arrayMove(items, activeIndex, overIndex);
      onDragEnd(newItems);
    }

    setActiveItem(null);
  };

  const handleDragCancel = () => {
    setActiveItem(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Card className='w-full'>
          <CardContent className='flex flex-col gap-4 p-2'>
            {items.map((item) => (
              <SortableItem key={item.id} item={item} />
            ))}
          </CardContent>
        </Card>
      </SortableContext>

      {createPortal(
        <DragOverlay>{activeItem ? <Item item={activeItem} isOpacityEnabled /> : null}</DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}
