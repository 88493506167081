import { ConfirmationDialog } from '@/components/confirmation-dialog';
import RHFTextInput from '@/components/form/rhf-text-input';
import LoadingSpinner from '@/components/loading-spinner';
import Heading from '@/components/typography/heading';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useRestaurantUpdate } from '@/hooks/useRestaurantUpdate';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ScrollArea } from '@ui/components';
import { X } from 'lucide-react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  displayName: z.string(),
  brightness: z.number(),
  contrast: z.number(),
  model: z.string(),
});

export default function CreateNewModel() {
  const params = useParams();
  const navigate = useNavigate();

  if (!params.restaurantSlug) {
    throw Error(`CreateNewModel requires a restaurantSlug url param`);
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const updateRestaurantMutation = useRestaurantUpdate(restaurantQuery.data?.id || '');

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      displayName: '',
      model: '',
      brightness: 1,
      contrast: 1,
    },
  });

  // Block navigating elsewhere when form has unsaved changes
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      !form.formState.isSubmitting &&
      form.formState.isDirty &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  const handleClose = () => {
    // TODO: check if form state is dirty
    const newPath = `/${params.restaurantSlug}/matterport-models`;
    navigate(newPath);
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (values) => {
    const existingModels = restaurantQuery.data?.layouts || [];
    const result = await updateRestaurantMutation.mutateAsync({
      id: restaurantQuery.data?.id || '',
      data: {
        layouts: [...existingModels, { ...values, condition: {}, isDefault: false, isActive: true }],
      },
    });
    if (result) {
      form.reset();
      navigate(`/${params.restaurantSlug}/matterport-models/${values.model}`);
    }
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <header className="flex justify-between px-5 py-3 border-b">
          <Heading>Create New Model</Heading>
          <button onClick={handleClose}>
            <X />
          </button>
        </header>

        <FormProvider {...form}>
          <ScrollArea className="flex-1">
            <form id="create-model-form" onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 px-5 py-3">
                <RHFTextInput label="Display Name" form={form} name="displayName" />
                <RHFTextInput label="Model ID" form={form} name="model" />
              </div>
            </form>
          </ScrollArea>
        </FormProvider>

        <footer className="p-3 border-t-[1px]">
          <Button
            form="create-model-form"
            type="submit"
            className="w-full"
            disabled={!form.formState.isDirty || form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? <LoadingSpinner /> : 'Confirm'}
          </Button>
        </footer>
      </div>

      {blocker.state === 'blocked' ? (
        <ConfirmationDialog
          open={blocker.state === 'blocked'}
          title="You have unsaved changes!"
          description={`Are you sure you want to leave? Your changes will not be saved.`}
          cancelLabel="Cancel"
          continueLabel="Discard Changes"
          onContinue={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      ) : null}
    </>
  );
}
