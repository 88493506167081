import TableService from '@/services/table.service';
import { Table } from '@repo/types';
import { useQuery } from '@tanstack/react-query';

const useTables = ({
  restaurantId,
  includeDeleted = false,
}: {
  restaurantId: string;
  includeDeleted?: boolean;
}) => {
  return useQuery<Table[], Error>({
    enabled: !!restaurantId,
    throwOnError: true,
    queryKey: ['tables', restaurantId],
    queryFn: () => TableService.findAllByRestaurant(restaurantId, includeDeleted),
    select: (data) => transformData(data),
  });
};

export { useTables };

const transformData = (tables: Table[]) => {
  return tables.map((table) => ({
    ...table,
    // add computed capacity field
    capacity: `${table.minGuests} - ${table.maxGuests}`,
    // add computed enabled field for easier/consistent use in forms
    enabled: !table.disabled,
  }));
};
