import { generalUpdateFail, generalUpdateSuccess } from '@/components/toasts';
import RestaurantService from '@/services/restaurant.service';
import { MatterportScan } from '@repo/types';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@ui/components';
import { AxiosError } from 'axios';

const useModelUpdate = (
  options?: UseMutationOptions<
    MatterportScan,
    AxiosError,
    { restaurantId: string; modelId: string; data: Partial<MatterportScan> }
  >,
) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation({
    mutationKey: ['updateModel'],
    mutationFn: ({
      restaurantId,
      modelId,
      data,
    }: {
      restaurantId: string;
      modelId: string;
      data: Partial<MatterportScan>;
    }) => RestaurantService.updateModel({ restaurantId, modelId, data }),
    onSuccess: (data) => {
      toast(generalUpdateSuccess(`Updated status for '${data.displayName}' model`));
      return Promise.all([queryClient.invalidateQueries({ queryKey: ['getRestaurantBySlug'] })]);
    },
    onError: (error) => {
      console.error(error);
      toast(generalUpdateFail);
    },
    ...options,
  });
};

export { useModelUpdate };
