import { MediaType } from '@repo/types';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ScrollArea } from '@ui/components';
import FileUpload from '@/components/file-upload';
import MediaUploads from '@/components/media-uploads';
import MediaUploadCard from '@/components/media-upload-card';
import { useParams } from 'react-router-dom';
import { useTable } from '@/hooks/useTable';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useState } from 'react';
import { useMediaDelete } from '@/hooks/useMediaDelete';
import { useTableUpdate } from '@/hooks/useTableUpdate';
import LoadingSpinner from '@/components/loading-spinner';
import { ConfirmationDialog } from '@/components/confirmation-dialog';

const formSchema = z.object({
  media: z.array(
    z.object({
      type: z.nativeEnum(MediaType),
      path: z.string(),
    }),
  ),
});

export default function Media() {
  const params = useParams();
  const [alertOpen, setAlertOpen] = useState(false);

  if (!params.restaurantSlug) {
    throw Error(`Edit Details page requires a restaurantSlug url param`);
  }

  if (!params.pinId) {
    throw Error(`Edit Details page requires a pinId url param`);
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const tableQuery = useTable({
    restaurantId: restaurantQuery.data!.id,
    tableId: params.pinId,
  });
  const tableData = tableQuery.data;

  const deleteMediaMutation = useMediaDelete();
  const updateTableMutation = useTableUpdate();

  const [imgPendingDelete, setImgPendingDelete] = useState('');

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      media: tableData?.media || [],
    },
  });

  const watchMedia = form.watch('media');

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await updateTableMutation.mutateAsync({
      restaurantId: restaurantQuery.data!.id,
      tableId: params.pinId!,
      data: values,
    });
  };

  const onDeleteClick = (path: string) => {
    setAlertOpen(true);
    setImgPendingDelete(path);
  };

  const onCancelDeleteClick = () => {
    setAlertOpen(false);
    setImgPendingDelete('');
  };

  const handleDelete = async () => {
    setAlertOpen(false);
    await deleteMediaMutation.mutateAsync({ tableId: params.pinId!, filePath: imgPendingDelete });
    setImgPendingDelete('');
  };

  return (
    <>
      <FormProvider {...form}>
        <ScrollArea className="flex-1">
          <form id="edit-pin-media-form" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 px-5 py-3">
              <FileUpload label="Images and Videos" onFilesChange={() => {}} collection={'Tables'}/>

              {!!watchMedia?.length && (
                <MediaUploads
                  form={form}
                  name="media"
                  initialItems={watchMedia.map((item) => ({
                    ...item,
                    id: item.path,
                    content: (
                      <MediaUploadCard
                        path={item.path}
                        deleteAction={() => onDeleteClick(item.path)}
                        variant={'full'}
                        isLoading={
                          deleteMediaMutation.isPending &&
                          deleteMediaMutation.variables.filePath === item.path
                        }
                      />
                    ),
                  }))}
                />
              )}
            </div>
          </form>
        </ScrollArea>
      </FormProvider>

      <footer className="p-3 border-t-[1px]">
        <Button
          form="edit-pin-media-form"
          type="submit"
          className="w-full"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
        >
          {form.formState.isSubmitting ? <LoadingSpinner /> : 'Save Changes'}
        </Button>
      </footer>

      <ConfirmationDialog
        open={alertOpen}
        title="Confirm deletion"
        description={`Are you sure you want to delete this item from '${tableData?.name}'?`}
        cancelLabel="Cancel"
        continueLabel="Delete"
        onContinue={handleDelete}
        onCancel={onCancelDeleteClick}
      />
    </>
  );
}
