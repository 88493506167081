import SettingsFees from '@/components/policies/settings-fees';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useParams } from 'react-router-dom';

export default function Fees() {
  const params = useParams();

  if (!params.restaurantSlug) {
    throw Error(`Edit Details page requires a restaurantSlug url param`);
  }

  if (!params.pinId) {
    throw Error(`Edit Details page requires a pinId url param`);
  }


  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const restaurantData = restaurantQuery.data;

  // OLD FEES PAGE
  return !restaurantData ? null : <SettingsFees restaurantId={restaurantData.id} tableId={params.pinId} />

  // TODO: COMBINE WITH NEW POLICIES PAGE
}
