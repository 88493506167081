import { adminApiClient } from '@/helpers/api.client';
import { useFirebase } from '@/hooks/useFirebase';
import { User, onIdTokenChanged, signInWithEmailAndPassword } from 'firebase/auth';
import React, { ReactNode, createContext, useEffect, useState } from 'react';

export interface AuthContextType {
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<User>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { auth } = useFirebase();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      setUser(user);
      setLoading(false);
      if (user) {
        adminApiClient.interceptors.request.use(async (config) => {
          const token = await user.getIdToken();
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        });
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const signOut = async () => {
    await auth.signOut();
    setUser(null);
  };

  const signIn = async (email: string, password: string): Promise<User> => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      return userCredential.user;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.code === 'auth/invalid-credential') {
        throw new Error('Invalid user name or password');
      } else if (e?.code === 'auth/too-many-requests') {
        throw new Error(
          'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
        );
      } else {
        console.error(e);
        throw new Error('An unexpected error occurred. Try again later.');
      }
    }
  };

  return <AuthContext.Provider value={{ user, loading, signIn, signOut }}>{children}</AuthContext.Provider>;
};
