import { generalUpdateFail, generalUpdateSuccess } from '@/components/toasts';
import RestaurantService from '@/services/restaurant.service';
import { Restaurant } from '@repo/types';
import { useToast } from '@repo/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useRestaurantUpdate = (id?: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Restaurant, Error, { id: string; data: Partial<Restaurant> }>({
    mutationKey: ['updateRestaurant', id],
    mutationFn: ({ id, data }) => RestaurantService.update(id, data),
    onSuccess: (data) => {
      toast(generalUpdateSuccess(`Saved changes to ${data.displayName}`));
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['restaurant', id] }),
        queryClient.invalidateQueries({ queryKey: ['restaurants'] }),
        queryClient.invalidateQueries({ queryKey: ['getRestaurantBySlug', data.slug] }),
      ]);
    },
    onError: (error) => {
      console.error(error);
      toast(generalUpdateFail);
    },
  });
};

export { useRestaurantUpdate };
