import CustomPolicySection from '@/components/policies/custom-policy-section';
import SubHeading from '@/components/typography/sub-heading';
import { groupPoliciesByName } from '@/helpers/policies.helper';
import { useRestaurantPolicies, useTablePolicies } from '@/hooks/usePolicies';
import { Policy, PolicyType, PolicyWithCutoff } from '@repo/types';
import { Button, Input, ScrollArea } from '@ui/components';
import { PlusCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PolicySection from './policy-section';

interface SettingsPoliciesProps {
  restaurantId: string;
  tableId?: string;
}

export default function SettingsPolicies({ restaurantId, tableId = undefined }: SettingsPoliciesProps) {
  const { data: restaurantPolicies } = useRestaurantPolicies(restaurantId);
  const { data: tablePolicies } = useTablePolicies(restaurantId, tableId);

  const [policies, setPolicies] = useState<Policy[] | PolicyWithCutoff[]>([]);
  const [bookingLeadTimePolicies, setBookingLeadTimePolicies] = useState<PolicyWithCutoff[]>([]);
  const [bookingHorizonPolicies, setBookingHorizonPolicies] = useState<PolicyWithCutoff[]>([]);
  const [bookingGracePeriodPolicies, setBookingGracePeriodPolicies] = useState<Policy[]>([]);
  const [modificationCutoffPolicies, setModificationCutoffPolicies] = useState<PolicyWithCutoff[]>([]);
  const [cancellationCutoffPolicies, setCancellationCutoffPolicies] = useState<PolicyWithCutoff[]>([]);
  const [customPolicies, setCustomPolicies] = useState<Policy[]>([]);

  const [customPoliciesByName, setCustomPoliciesByName] =
    useState<Record<string, { description: string; policies: Policy[] }>>();

  const [isCreatingNewType, setIsCreatingNewType] = useState(false);

  const [newPolicyName, setNewPolicyName] = useState('');
  const [newPolicyDescription, setNewPolicyDescription] = useState('');

  const handleAddPolicy = (newPolicy: Policy | PolicyWithCutoff) => {
    setPolicies([...policies, newPolicy]);
  };

  const handleRemovePolicy = (id: string) => {
    setPolicies(policies.filter((policy) => policy.id !== id));
  };

  useEffect(() => {
    if (!restaurantPolicies && !tablePolicies) {
      setPolicies([]);
    } else {
      if (tableId) {
        setPolicies([...(restaurantPolicies || []), ...(tablePolicies || [])]);
      } else {
        setPolicies([...(restaurantPolicies || [])]);
      }
    }
  }, [restaurantPolicies, tableId, tablePolicies]);

  useEffect(() => {
    setBookingLeadTimePolicies(
      policies.filter((policy) => policy.type === PolicyType.BOOKING_LEAD_TIME) as PolicyWithCutoff[],
    );
    setBookingHorizonPolicies(
      policies.filter((policy) => policy.type === PolicyType.BOOKING_HORIZON) as PolicyWithCutoff[],
    );
    setBookingGracePeriodPolicies(
      policies.filter((policy) => policy.type === PolicyType.GRACE_PERIOD) as Policy[],
    );
    setModificationCutoffPolicies(
      policies.filter((policy) => policy.type === PolicyType.MODIFICATION) as PolicyWithCutoff[],
    );
    setCancellationCutoffPolicies(
      policies.filter((policy) => policy.type === PolicyType.CANCELLATION) as PolicyWithCutoff[],
    );
    setCustomPolicies(policies.filter((policy) => !!policy.is_custom) as Policy[]);
  }, [policies, tableId]);

  useEffect(() => {
    if (customPolicies.length > 0) {
      setCustomPoliciesByName(groupPoliciesByName(customPolicies));
    } else {
      setCustomPoliciesByName(undefined);
    }
  }, [customPolicies]);

  const toggleCreateCustom = () => {
    clearInputFields();
    setIsCreatingNewType((isCreating) => !isCreating);
  };

  const clearInputFields = () => {
    setNewPolicyName('');
    setNewPolicyDescription('');
  };

  const handlePolicyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setNewPolicyName(e.target.value);
  const handlePolicyDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewPolicyDescription(e.target.value);

  const createNewPolicyType = () => {
    const placeholderPolicy: Policy = {
      id: uuidv4(),
      tableId: tableId,
      restaurantId: restaurantId,
      type: newPolicyName,
      is_custom: true,
      name: newPolicyName,
      description: newPolicyDescription,
      condition: {},
      displays: [],
      isPlaceholder: true,
    };

    handleAddPolicy(placeholderPolicy);
    toggleCreateCustom();
  };

  return (
    <ScrollArea className="flex-1 p-3">
      <div className="flex flex-col gap-6 py-4">
        <div className="flex flex-col gap-4">
          <SubHeading>Booking</SubHeading>
          <PolicySection
            policies={bookingLeadTimePolicies}
            onAddPolicy={handleAddPolicy}
            onRemovePolicy={handleRemovePolicy}
            type={PolicyType.BOOKING_LEAD_TIME}
            restaurantId={restaurantId}
            tableId={tableId}
          />
          <PolicySection
            policies={bookingHorizonPolicies}
            onAddPolicy={handleAddPolicy}
            onRemovePolicy={handleRemovePolicy}
            type={PolicyType.BOOKING_HORIZON}
            restaurantId={restaurantId}
            tableId={tableId}
          />
          <PolicySection
            policies={bookingGracePeriodPolicies}
            onAddPolicy={handleAddPolicy}
            onRemovePolicy={handleRemovePolicy}
            type={PolicyType.GRACE_PERIOD}
            restaurantId={restaurantId}
            tableId={tableId}
          />
        </div>

        <div className="flex flex-col gap-4">
          <SubHeading>Cutoff</SubHeading>

          <PolicySection
            policies={modificationCutoffPolicies}
            onAddPolicy={handleAddPolicy}
            onRemovePolicy={handleRemovePolicy}
            type={PolicyType.MODIFICATION}
            restaurantId={restaurantId}
            tableId={tableId}
          />
          <PolicySection
            policies={cancellationCutoffPolicies}
            onAddPolicy={handleAddPolicy}
            onRemovePolicy={handleRemovePolicy}
            type={PolicyType.CANCELLATION}
            restaurantId={restaurantId}
            tableId={tableId}
          />
        </div>

        <div className="flex flex-col gap-4">
          <SubHeading>Additional Policies</SubHeading>

          {customPoliciesByName &&
            Object.entries(customPoliciesByName).map(([name, { description, policies }], index) => (
              <CustomPolicySection
                key={`custom--${name}--${index}`}
                policies={policies}
                onAddPolicy={handleAddPolicy}
                onRemovePolicy={handleRemovePolicy}
                title={name}
                name={name}
                description={description}
                restaurantId={restaurantId}
                tableId={tableId}
              />
            ))}

          {isCreatingNewType ? (
            <div className="flex flex-col gap-2 py-2">
              <h5 className="font-medium">New Custom Policy</h5>
              <Input placeholder="Policy name" value={newPolicyName} onChange={handlePolicyNameChange} />
              <Input
                placeholder="Description (optional)"
                value={newPolicyDescription}
                onChange={handlePolicyDescriptionChange}
              />

              <div className="flex">
                <Button type="button" onClick={createNewPolicyType} disabled={newPolicyName === ''}>
                  Create Policy
                </Button>
                <Button type="button" variant="ghost" onClick={toggleCreateCustom}>
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <Button type="button" variant="outline" className="my-2 space-x-2" onClick={toggleCreateCustom}>
              <span>Create new custom policy</span> <PlusCircle size={18} />
            </Button>
          )}
        </div>
      </div>
    </ScrollArea>
  );
}
