import { INavLink } from '@/config/nav-config';
import { ChevronRight } from 'lucide-react';
import { NavLink } from 'react-router-dom';

type NavLinksProps = {
  title?: string;
  links: INavLink[];
  isExpanded?: boolean;
};

export default function NavLinks({ title, links, isExpanded = true }: NavLinksProps) {
  return (
    <nav className={`flex flex-col gap-4 py-1 ${isExpanded ? 'px-2' : 'px-0'}`}>
      {isExpanded && title && <h2 className="py-2 text-sm font-semibold text-zinc-400 ">{title}</h2>}
      <ul className="flex flex-col gap-2">
        {links.map(({ href, icon, title, disabled }) => (
          <li key={href}>
            <NavLink
              to={href}
              className={({ isActive, isPending }) =>
                `flex rounded-sm p-2 justify-between items-center text-sm hover:bg-zinc-200 dark:hover:bg-zinc-800 transition-all ${isActive ? 'active' : isPending ? 'pending' : ''} ${disabled ? 'disabled' : ''}`
              }
            >
              <div className="flex items-center gap-2">
                {icon}
                {isExpanded && <span>{title}</span>}
              </div>
              {isExpanded && <ChevronRight />}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}
