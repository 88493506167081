import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFSelect from '@/components/form/rhf-select';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import { BasePolicyCard } from '@/components/policies/cards';
import { BookingHorizonFormData, bookingHorizonFormSchema } from '@/components/policies/cards/schemas';
import { CutoffUnitOptions } from '@/config/dropdown-options/cutoffs';
import {
  DEFAULT_POLICY_ICON,
  PolicyDefaultDisclaimerMap,
  PolicyIconMap,
} from '@/constants/policies.constants';
import {
  getDayOfWeekCondition,
  getHasItemCondition,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  transformCutoff,
} from '@/helpers/form.helper';
import {
  CreatePolicyRequest,
  CutoffType,
  CutoffUnit,
  DisplayLocation,
  PolicyType,
  PolicyWithCutoff,
  UpdatePolicyRequest,
} from '@repo/types';
import * as React from 'react';

interface BookingHorizonCardProps {
  policy: PolicyWithCutoff;
  maxWeight: number;
}

const BookingHorizonCard: React.FC<BookingHorizonCardProps> = ({ policy, maxWeight }) => {
  const formDefaultValues: BookingHorizonFormData = {
    cutoff: {
      value: policy.cutoff.value || 0,
      unit: policy.cutoff.unit || CutoffUnit.NONE,
      timeOfDay: policy.cutoff.timeOfDay || '',
    },
    disclaimer: getInitialDisclaimer(policy, PolicyDefaultDisclaimerMap[policy.type as PolicyType] || ''),
    allowRequests: false,
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
    },
  };

  const getPolicyCreateData = (formData: BookingHorizonFormData): CreatePolicyRequest => ({
    name: PolicyType.BOOKING_HORIZON,
    description: PolicyType.BOOKING_HORIZON,
    type: PolicyType.BOOKING_HORIZON,
    cutoff: transformCutoff(formData.cutoff, CutoffType.CREATION),
    displays: [
      {
        title: 'Booking Horizon',
        location: DisplayLocation.OTHER,
        icon: PolicyIconMap[PolicyType.BOOKING_HORIZON] || DEFAULT_POLICY_ICON,
        description: formData.disclaimer,
      },
    ],
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getHasItemCondition(policy),
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: BookingHorizonFormData): UpdatePolicyRequest => ({
    ...policy,
    id: policy.id,
    cutoff: transformCutoff(formData.cutoff, CutoffType.CREATION),
    displays: policy.displays.map((display) => ({
      ...display,
      description: formData.disclaimer,
    })),
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getHasItemCondition(policy),
      },
    },
  });

  return (
    <BasePolicyCard<BookingHorizonFormData>
      formSchema={bookingHorizonFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={PolicyType.BOOKING_HORIZON}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <PolicyDayOfWeekSelector form={form} />

          <div className="grid gap-4 mb-4 sm:grid-cols-3">
            <RHFTextInput
              form={form}
              name="cutoff.timeOfDay"
              label="Time of Day"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              form={form}
              name="cutoff.value"
              label="Value"
              inputProps={{ type: 'number', min: 0 }}
            />
            <RHFSelect form={form} name="cutoff.unit" label="Unit" options={CutoffUnitOptions} />
          </div>

          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" />

          <div className="flex justify-end">
            <RHFSwitch form={form} name="allowRequests" label="Allow Requests?" className="w-[170px]" />
          </div>
        </>
      )}
    </BasePolicyCard>
  );
};

export default BookingHorizonCard;
