import SettingsPolicies from '@/components/policies/settings-policies';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useParams } from 'react-router-dom';

export default function Policies() {
  const params = useParams();
  // const [searchParams] = useSearchParams();

  if (!params.restaurantSlug) {
    throw Error(`Edit Details page requires a restaurantSlug url param`);
  }

  if (!params.pinId) {
    throw Error(`Edit Details page requires a pinId url param`);
  }

  // const currentPolicyType = searchParams.get('type');

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const restaurantData = restaurantQuery.data;

  // const { data: restaurantPolicies } = useRestaurantPolicies(restaurantData!.id);
  // const { data: tablePolicies } = useTablePolicies(restaurantData!.id, params.pinId);

  // const policies = [...(restaurantPolicies || []), ...(tablePolicies || [])];

  // const groupedPolicies = policies.reduce((acc: Record<string, Policy[]>, policy) => {
  //   const { type } = policy;
  //   if (!acc[type]) {
  //     acc[type] = [];
  //   }
  //   acc[type].push(policy);
  //   return acc;
  // }, {});

  // const navConfig: INavLink[] = Object.keys(groupedPolicies).map((value) => ({
  //   title: value,
  //   href: `?type=${value}`,
  // }));

  // OLD POLICIES PAGE
  return !restaurantData ? null : <SettingsPolicies restaurantId={restaurantData.id} tableId={params.pinId} />

  // TODO: NEW POLICIES PAGE
  // return (
  //   <>
  //     <div className="flex flex-col flex-1 gap-4 p-3">
  //       {currentPolicyType && groupedPolicies[currentPolicyType] ? (
  //         Object.values(groupedPolicies[currentPolicyType]).map((value) => <div key={value.id}>
  //           <h2>{value.name}</h2>
  //           <p>{value.id}</p>
  //           <small>Restaurant: {value.restaurantId}</small>
  //           <small>Table: {value.tableId}</small>
  //         </div>)
  //       ) : (
  //         <NavLinks links={navConfig} />
  //       )}
  //     </div>

  //     <footer className="p-3 border-t-[1px]">
  //       <Button
  //         // form=""
  //         type="submit"
  //         className="w-full"
  //         variant='secondary'
  //         // disabled={!form.formState.isDirty || form.formState.isSubmitting}
  //       >
  //         Create New
  //         {/* {form.formState.isSubmitting ? <LoadingSpinner /> : 'Confirm'} */}
  //       </Button>
  //     </footer>
  //   </>
  // );
}
