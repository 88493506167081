import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Switch,
} from "@ui/components";
import { cn } from "@ui/lib/utils";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

interface RHFTextInput<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  description?: string;
  className?: string;
  onChange?: (value: boolean) => void;
}

export default function RHFSwitch<TFieldValues extends FieldValues>({
  form,
  name,
  label,
  description,
  className,
  onChange,
}: RHFTextInput<TFieldValues>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('flex items-center justify-center w-full h-10 space-x-2 space-y-0', className)}>
          <FormLabel className="text-foreground">{label}</FormLabel>
          <FormControl>
            <Switch
              checked={field.value}
              onCheckedChange={(value) => {
                field.onChange(value);
                if (onChange) {
                  onChange(value);
                }
              }}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
