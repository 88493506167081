import RestaurantService from "@/services/restaurant.service";
import { Restaurant } from "@repo/types";
import { useQuery } from "@tanstack/react-query";

const useRestaurants = () => {
  return useQuery<Restaurant[], Error>({
    throwOnError: true,
    queryKey: ["restaurants"],
    queryFn: () => RestaurantService.findAll(),
    select: (data) => transformData(data),
  });
};

export { useRestaurants };

const transformData = (restaurants: Restaurant[]) => {
  return restaurants.map((restaurant) => ({
    ...restaurant,
    // make sure all restaurants have an `is_live` property
    is_live: restaurant.is_live ?? false,
  }));
};
