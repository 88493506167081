import { Country, StripeRegion } from "@repo/types";
import { Country as CountryCode } from "react-phone-number-input";

export const TablzCountryOptions = Object.values(Country).map((option) => ({
  label: option,
  value: option,
}));

export const StripeCountryOptions = Object.values(StripeRegion).map((option) => ({
  label: option.toUpperCase(),
  value: option,
}));

export const PhoneCountryCodeMap: Record<Country, CountryCode> = {
  "United Arab Emirates": "UA",
  "United States": "US",
  "Canada": "CA"
};
