import { CopyToClipboardButton } from '@/components/copy-to-clipboard-button';
import {
  Button,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@ui/components';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

interface RHFTextInput<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  placeholder?: string;
  description?: string;
  canCopyToClipboard?: boolean;
  className?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
}

export default function RHFTextInput<TFieldValues extends FieldValues>({
  form,
  name,
  label,
  placeholder,
  description,
  canCopyToClipboard = false,
  className,
  inputProps,
  disabled
}: RHFTextInput<TFieldValues>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel className="flex items-center gap-2">
            {label}
            {canCopyToClipboard && <CopyToClipboardButton text={field.value} />}
          </FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              {...field}
              {...inputProps}
              onChange={(e) => {
                const value = e.target.value;

                if (inputProps?.type === 'number' && !isNaN(parseFloat(value))) {
                  const newValue = parseFloat(value);
                  field.onChange(newValue);
                } else {
                  field.onChange(value);
                }
              }}
              disabled={!!disabled}
            />
          </FormControl>
          {inputProps?.type == 'time' ? (
            <Button
              className="p-0"
              variant="link"
              onClick={(e) => {
                e.preventDefault();
                form.setValue(name, '' as PathValue<TFieldValues, Path<TFieldValues>>, {
                  shouldDirty: true,
                });
              }}
            >
              Clear
            </Button>
          ) : null}
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
