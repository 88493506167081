import { useQuery } from '@tanstack/react-query';
import { Event } from "@repo/types";
import EventService from "@/services/event.service";

const useEvents = (restaurantId: string) => {
    return useQuery<Event[], Error>({
        queryKey: ["events", restaurantId],
        queryFn: () => EventService.findAllRestaurantEvents(restaurantId),
    });
};

export { useEvents };