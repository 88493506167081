import { Box, LineChart, MapPin, Star } from "lucide-react";

export interface INavLink {
  title: string;
  href: string;
  icon?: JSX.Element;
  disabled?: boolean;
}

export const restaurantNavConfig: (slug: string) => INavLink[] = (slug) => [
    {
    title: 'Events',
    href: `${slug}/special-events`,
    icon: <Star />
  },
  {
    title: 'Models',
    href: `${slug}/matterport-models`,
    icon: <Box />
  },
  {
    title: 'Pins',
    href: `${slug}/matterport-pins`,
    icon: <MapPin />
  }
  // {
  //   title: "Restaurants",
  //   href: "/restaurants",
  //   icon: <Settings2 />,
  // },
  // {
  //   title: "Tables",
  //   href: "/tables",
  //   icon: <TableIcon />,
  // },
  // {
  //   title: "Bookings",
  //   href: "/bookings",
  //   icon: <BookOpen />,
  //   disabled: true
  // },
  // {
  //   title: "Requests",
  //   href: "/requests",
  //   icon: <MailQuestion />,
  //   disabled: true
  // },
  // {
  //   title: "Add-Ons",
  //   href: "/addons",
  //   icon: <UtensilsCrossed />,
  //   disabled: true
  // },
];

export const settingsNavConfig: (slug: string) => INavLink[] = (slug) => [
  {
    title: 'Dashboard',
    href: `${slug}/dashboard`,
    icon: <LineChart />,
    disabled: true
  }
  // {
  //   title: "Taxes & Payouts",
  //   href: "/payouts",
  //   icon: <DollarSign />,
  //   disabled: true
  // },
  // {
  //   title: "Integrations",
  //   href: "/integrations",
  //   icon: <Plug />,
  //   disabled: true
  // },
  // {
  //   title: "Holidays & Closures",
  //   href: "/closures",
  //   icon: <CalendarOff />,
  //   disabled: true
  // },
  // {
  //   title: "Feature Flags",
  //   href: "/flags",
  //   icon: <Flag />,
  //   disabled: true
  // },
];
