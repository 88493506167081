import {
  Avatar,
  AvatarFallback,
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@repo/ui';
import { cn } from '@repo/ui/lib/utils';
import { Check, ChevronsUpDown, Search } from 'lucide-react';
import { useState } from 'react';

interface ComboboxProps {
  value: string | undefined;
  onValueChange: (value: string) => void;
  options: SelectOption[];
  label: string;
  isExpanded?: boolean;
}

type SelectOption = {
  label: string;
  value: string;
};

export function Combobox({ value, onValueChange, options, label, isExpanded = true }: ComboboxProps) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {isExpanded ? (
          <Button variant="outline" role="combobox" aria-expanded={open} className="justify-between w-full overflow-hidden">
            <span className='overflow-hidden text-ellipsis whitespace-nowrap'>{value ? options.find((option) => option.value === value)?.label : label}</span>
            <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
          </Button>
        ) : (
          <Avatar className="cursor-pointer h-9 w-9" title="Select restaurant">
            <AvatarFallback>
              <Search />
            </AvatarFallback>
          </Avatar>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder="Search restaurant..." />
          <CommandList>
            <CommandEmpty>No results.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  keywords={[option.label]}
                  key={option.value}
                  value={option.value}
                  onSelect={(currentValue) => {
                    setOpen(false);
                    onValueChange(currentValue);
                  }}
                >
                  <Check
                    className={cn('mr-2 h-4 w-4', value === option.value ? 'opacity-100' : 'opacity-0')}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
