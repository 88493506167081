import Logo from '@/components/logo-full';
import { UserAuthForm } from '@/components/user-auth-form/user-auth-form';
import { useAuth } from '@/hooks/useAuth';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function AuthenticationPage() {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <div className="h-screen">
      <div className="container relative h-screen flex flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col p-10 dark:text-white lg:flex dark:border-r dark:bg-black">
          <div
            className="absolute inset-0 bg-muted"
            // style={{
            //   backgroundImage:
            //     "url('data:image/svg+xml,%3Csvg width%3D%22400%22 height%3D%22400%22 xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cdefs%3E%3Cpattern id%3D%22diagonal-stripes%22 patternUnits%3D%22userSpaceOnUse%22 width%3D%22200%22 height%3D%22200%22 patternTransform%3D%22rotate(45)%22%3E%3Crect width%3D%22200%22 height%3D%2266.67%22 fill%3D%22%231A2D4B%22 /%3E%3Crect y%3D%2266.67%22 width%3D%22200%22 height%3D%2266.67%22 fill%3D%22%2356428C%22 /%3E%3Crect y%3D%22133.34%22 width%3D%22200%22 height%3D%2266.67%22 fill%3D%22%23E7475E%22 /%3E%3C/pattern%3E%3C/defs%3E%3Crect width%3D%22100%25%22 height%3D%22100%25%22 fill%3D%22url(%23diagonal-stripes)%22 /%3E%3C/svg%3E')",
            // }}
          />
          <div className="relative z-20 flex items-center text-lg font-medium dark:brightness-0 dark:invert">
            <Logo />
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Great hospitality is about making people feel taken care of, safe, and even a little
                bit special.&rdquo;
              </p>
              <footer className="text-sm">Will Guidara</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">Sign in to your account</h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to sign in to your account
              </p>
            </div>
            <UserAuthForm />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{' '}
              <Link to="/terms" className="underline underline-offset-4 hover:text-primary">
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link to="/privacy" className="underline underline-offset-4 hover:text-primary">
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
