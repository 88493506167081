import { renderCheck } from '@/components/form/rhf-combobox/helper';
import { CommandItem } from '@ui/components';
import { ControllerRenderProps, FieldValues, Path, PathValue } from 'react-hook-form';

export type SelectOption<T> = {
  label: string;
  value: PathValue<T, Path<T>>;
  icon?: React.ReactNode;
};

interface SelectOptionsProps<TFieldValues extends FieldValues> {
  options: SelectOption<TFieldValues>[];
  field: ControllerRenderProps<TFieldValues>;
  handleSelect: (
    value: PathValue<TFieldValues, Path<TFieldValues>>,
    field: ControllerRenderProps<TFieldValues>,
  ) => void;
}

export function SelectOptions<TFieldValues extends FieldValues>({
  options,
  field,
  handleSelect,
}: SelectOptionsProps<TFieldValues>) {
  return options.map((option) => (
    <CommandItem value={option.label} key={option.value} onSelect={() => handleSelect(option.value, field)}>
      {renderCheck(option.value, field)}
      <span className="flex-1">{option.label}</span>
    </CommandItem>
  ));
}
