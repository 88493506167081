import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import { BasePolicyCard } from '@/components/policies/cards';
import { GratuityFeeFormData, gratuityFeeFormSchema } from '@/components/policies/cards/schemas';
import { PolicyDefaultDisclaimerMap } from '@/constants/policies.constants';
import {
  getBetweenTimesCondition,
  getDayOfWeekCondition,
  getDisplaysArray,
  getHasItemCondition,
  getInitialBetweenTimes,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  getInitialTitle,
} from '@/helpers/form.helper';
import { CreatePolicyRequest, DisplayLocation, Policy, PolicyType, UpdatePolicyRequest } from '@repo/types';
import * as React from 'react';

interface GratuityFeeCardProps {
  policy: Policy;
  maxWeight: number;
}

const GratuityFeeCard: React.FC<GratuityFeeCardProps> = ({ policy, maxWeight }) => {
  const formDefaultValues: GratuityFeeFormData = {
    title: getInitialTitle(policy, 'Gratuity'),
    disclaimer: getInitialDisclaimer(policy, PolicyDefaultDisclaimerMap[policy.type as PolicyType] || ''),
    displayTable: policy.displays.some((display) => display.location === DisplayLocation.TABLE),
    displayEmail: policy.displays.some((display) => display.location === DisplayLocation.EMAIL),
    displayCheckout: policy.displays.some((display) => display.location === DisplayLocation.CHECKOUT),
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
      betweenTimes: getInitialBetweenTimes(policy),
    },
  };

  const getPolicyCreateData = (formData: GratuityFeeFormData): CreatePolicyRequest => ({
    name: PolicyType.GRATUITY,
    description: PolicyType.GRATUITY,
    type: PolicyType.GRATUITY,
    displays: getDisplaysArray(formData),
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: GratuityFeeFormData): UpdatePolicyRequest => ({
    ...policy,
    id: policy.id,
    displays: getDisplaysArray(formData),
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
      },
    },
  });

  return (
    <BasePolicyCard<GratuityFeeFormData>
      formSchema={gratuityFeeFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={PolicyType.GRATUITY}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <PolicyDayOfWeekSelector form={form} />
          <div className="grid grid-cols-2 gap-4">
            <RHFTextInput
              form={form}
              name="condition.betweenTimes.0"
              label="Start"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              form={form}
              name="condition.betweenTimes.1"
              label="End"
              inputProps={{ type: 'time' }}
            />
          </div>

          <RHFTextInput form={form} name="title" label="Title" canCopyToClipboard />

          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" canCopyToClipboard />
          <div className="grid gap-4 sm:grid-cols-3">
            <RHFSwitch
              form={form}
              name="displayTable"
              label="Table"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayCheckout"
              label="Checkout"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayEmail"
              label="Email"
              className="justify-between p-3 border rounded-md"
            />
          </div>
        </>
      )}
    </BasePolicyCard>
  );
};

export default GratuityFeeCard;
