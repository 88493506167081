import RestaurantService from '@/services/restaurant.service';
import { Restaurant } from '@repo/types';
import { useQuery } from '@tanstack/react-query';

const useRestaurant = (id: string) => {
  return useQuery<Restaurant, Error>({
    queryKey: ['restaurant', id],
    queryFn: () => RestaurantService.findOne(id),
    // select: (data) => transformData(data),
  });
};

export { useRestaurant };

// const transformData = (restaurant: Partial<Restaurant>): Partial<Restaurant> => {
//   const {
//     // Account
//     id,
//     is_live = false,
//     name = '',
//     slug = '',
//     displayName = '',
//     phoneNumber = '',
//     contactEmail = '',
//     address = '',
//     city = '',
//     region = '',
//     postalCode = '',
//     country = Country.USA,
//     timezone = Timezone.EASTERN,
//     displayAddress = '',

//     // Links
//     directionsUrl = '',
//     menuUrl = '',

//     // Accounting
//     accountId = '',
//     stripeRegion = StripeRegion.USA,
//     currency = Currency.USD,
//     // taxes = [],
//     // payouts = [],

//     // Matterport
//     model = '',
//     originalBalanceOwed = 0,
//     isBalancePaidBack = false,
//     brightness = 0,
//     contrast = 0,
//     layouts = [],

//     // Schedule
//     closed = {
//       dates: [],
//       weekdays: [],
//     },
//   } = restaurant;

//   return {
//     // Account
//     id,
//     is_live,
//     name,
//     slug,
//     displayName,
//     phoneNumber,
//     contactEmail,
//     address,
//     city,
//     region,
//     postalCode,
//     country,
//     timezone,
//     displayAddress,

//     // Links
//     directionsUrl,
//     menuUrl,

//     // Accounting
//     accountId,
//     stripeRegion,
//     currency,
//     // taxes,
//     // payouts,

//     // Matterport
//     model,
//     originalBalanceOwed,
//     isBalancePaidBack,
//     brightness,
//     contrast,
//     layouts,

//     // Schedule
//     closed,
//   };
// };
