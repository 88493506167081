import { policyDeleteFail, policyDeleteSuccess } from "@/components/toasts";
import PolicyService from "@/services/policy.service";
import { Policy } from "@repo/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";

const useRestaurantPolicyDelete = (restaurantId: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Policy, Error, { restaurantId: string; policyId: string }>({
    mutationKey: ["deleteRestaurantPolicy", restaurantId],
    mutationFn: ({ restaurantId, policyId }) => PolicyService.deleteRestaurantPolicy(restaurantId, policyId),
    onSuccess: () => {
      toast(policyDeleteSuccess);
      return queryClient.invalidateQueries({ queryKey: ["restaurantPolicies", restaurantId] });
    },
    onError: (error) => {
      console.error(error);
      toast(policyDeleteFail);
    },
  });
};

export { useRestaurantPolicyDelete };
