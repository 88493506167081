import { Timezone } from "@repo/types";

const TimezoneDisplayNameMap: Record<Timezone, string> = {
  "America/Chicago": "CST/CDT",
  "America/Denver": "MST/MDT",
  "America/Los_Angeles": "PST/PDT",
  "America/New_York": "EST/EDT",
  "Asia/Dubai": "+04",
};

export const TimezoneOptions = Object.values(Timezone).map((option) => ({
  label: `${option} (${TimezoneDisplayNameMap[option]})`,
  value: option,
}));