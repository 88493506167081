import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/components";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

interface RHFSelect<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  description?: string;
  placeholder?: string;
  options: { label: string; value: string }[];
}

export default function RHFSelect<TFieldValues extends FieldValues>({
  form,
  name,
  label,
  description,
  placeholder,
  options,
}: RHFSelect<TFieldValues>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className='flex items-center gap-2'>{label}</FormLabel>
          <FormControl>
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger>
                <SelectValue defaultValue="" placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent position='popper'>
                {options.map((o) => (
                  <SelectItem key={o.label} value={o.value}>
                    {o.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
