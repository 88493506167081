import { adminApiClient } from "@/helpers/api.client";
import { CreatePolicyRequest, Policy, UpdatePolicyRequest } from "@repo/types";

const findAllRestaurantPolicies = async (restaurantId: string) => {
  const response = await adminApiClient.get<Policy[]>(
    `/restaurants/${restaurantId}/policies`
  );
  return response.data;
};

const findAllTablePolicies = async (restaurantId: string, tableId: string) => {
  const response = await adminApiClient.get<Policy[]>(
    `/restaurants/${restaurantId}/tables/${tableId}/policies`
  );
  return response.data;
};

const createRestaurantPolicy = async (
  restaurantId: string,
  policy: CreatePolicyRequest
) => {
  const response = await adminApiClient.post<Policy>(
    `/restaurants/${restaurantId}/policies`,
    policy
  );
  return response.data;
};

const updateRestaurantPolicy = async (
  restaurantId: string,
  policy: UpdatePolicyRequest
) => {
  const response = await adminApiClient.patch<Policy>(
    `/restaurants/${restaurantId}/policies/${policy.id}`,
    policy
  );
  return response.data;
};

const createTablePolicy = async (
  restaurantId: string,
  tableId: string,
  policy: CreatePolicyRequest
) => {
  const response = await adminApiClient.post<Policy>(
    `/restaurants/${restaurantId}/tables/${tableId}/policies`,
    policy
  );
  return response.data;
};

const updateTablePolicy = async (
  restaurantId: string,
  tableId: string,
  policy: UpdatePolicyRequest
) => {
  const response = await adminApiClient.patch<Policy>(
    `/restaurants/${restaurantId}/tables/${tableId}/policies/${policy.id}`,
    policy
  );
  return response.data;
};

const deleteRestaurantPolicy = async (
  restaurantId: string,
  policyId: string
) => {
  const response = await adminApiClient.delete(
    `/restaurants/${restaurantId}/policies/${policyId}`
  );
  return response.data;
};

const deleteTablePolicy = async (
  restaurantId: string,
  tableId: string,
  policyId: string
) => {
  const response = await adminApiClient.delete(
    `/restaurants/${restaurantId}/tables/${tableId}/policies/${policyId}`
  );
  return response.data;
};

const PolicyService = {
  findAllRestaurantPolicies,
  findAllTablePolicies,
  createRestaurantPolicy,
  updateRestaurantPolicy,
  deleteRestaurantPolicy,
  createTablePolicy,
  updateTablePolicy,
  deleteTablePolicy,
};

export default PolicyService;
