import { MatterportScan } from "@repo/types";

export const updateDefaultModelCondition = (models: MatterportScan[]) => {
  const defaultModelIndex = models.findIndex((m) => m.isDefault);
  if (defaultModelIndex === -1) return models;

  const nonDefaultConditions = models.filter((model) => !model.isDefault).map((model) => model.condition);
  models[defaultModelIndex].condition = {
    ...models[defaultModelIndex].condition,
    not: {
      or: nonDefaultConditions
    }
  }

  return models;
};

export const removeNotConditionFromCurrentModel = (models: MatterportScan[], currentModel: MatterportScan) => {
  return models.map((model) => {
    if (model.model === currentModel.model) {
      return {
        ...model,
        condition: {
          ...model.condition,
          not: undefined,
        },
      };
    }
    return model;
  });
};