import { Table } from '@tanstack/react-table';
import { Button, Checkbox, Label } from '@ui/components';
import { X } from 'lucide-react';
import { DataTableFacetedFilter, FacetedFilterOption } from './data-table-faceted-filter';
import { DataTableFilter } from './data-table-filter';
import { DataTableViewOptions } from './data-table-view-options';
import { useState } from 'react';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  searchFilters?: DataTableSearchFilter[];
  facetedFilters?: DataTableFacetedFilter[];
  showDeletedRecordsCheckbox?: boolean;
  onShowDeletedRecordsChange?: (checked: boolean) => void;
}

export type DataTableSearchFilter = { placeholder: string; columnId: string };
export type DataTableFacetedFilter = { title: string; columnId: string; options: FacetedFilterOption[] };

export function DataTableToolbar<TData>({
  table,
  searchFilters,
  facetedFilters,
  showDeletedRecordsCheckbox = false,
  onShowDeletedRecordsChange
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const [showDeletedRecords, setShowDeletedRecords] = useState(false);

  const handleCheckboxChange = (checked: boolean) => {
    setShowDeletedRecords(checked);
    if (onShowDeletedRecordsChange) {
      onShowDeletedRecordsChange(checked);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col-reverse items-start flex-1 gap-y-2 sm:flex-row sm:items-center sm:space-x-2">
        {searchFilters?.map((filter) => (
          <DataTableFilter
            key={filter.columnId}
            table={table}
            placeholder={filter.placeholder}
            columnId={filter.columnId}
          />
        ))}

        {facetedFilters?.map((filter) => (
          <DataTableFacetedFilter
            key={filter.columnId}
            column={table.getColumn(filter.columnId)}
            title={filter.title}
            options={filter.options}
          />
        ))}
        {/* </div> */}
        {isFiltered && (
          <Button variant="ghost" onClick={() => table.resetColumnFilters()} className="h-8 px-2 lg:px-3">
            Reset
            <X className="w-4 h-4 ml-2" />
          </Button>
        )}
      </div>
      <div className="flex gap-4">
        {showDeletedRecordsCheckbox && (
          <div className="flex items-center space-x-2">
            <Checkbox
              id="show-deleted-records"
              checked={showDeletedRecords}
              onCheckedChange={handleCheckboxChange}
            />
            <Label htmlFor="show-deleted-records">Show deleted records</Label>
          </div>
        )}
        <DataTableViewOptions table={table} />
      </div>
    </div>
  );
}
