import MediaService, { DeleteMediaDTO } from "@/services/media.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useMediaDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["deleteMedia"],
    mutationFn: (data: DeleteMediaDTO) => MediaService.deleteMediaItem(data),
    onSuccess: (_, { tableId }) => {
      queryClient.invalidateQueries({ queryKey: ["table", tableId] });
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};

export { useMediaDelete };
