import WithTooltip from "@/components/with-tooltip";
import { Button } from "@repo/ui";
import { Check, Copy } from "lucide-react";
import { useState } from "react";

export const CopyToClipboardButton = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
  };
  return (
    <WithTooltip content={isCopied ? null : 'Copy'}>
      <Button
        variant='ghost'
        className='h-full p-0'
        onClick={(e) => {
          e.preventDefault(); // don't bubble up to parent
          copyTextToClipboard(text)
            .then(() => {
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            })
            .catch((error) => console.log({ error }));
        }}
      >
        {isCopied ? <CopiedIndicator /> : <Copy size={14} />}
      </Button>
    </WithTooltip>
  );
};

const CopiedIndicator = () => {
  return (
    <div className='flex gap-1 items-center h-[14px]'>
      <Check size={14} />
      <small>Copied!</small>
    </div>
  );
};
