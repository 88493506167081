import { policyCreateFail, policyCreateSuccess } from "@/components/toasts";
import PolicyService from "@/services/policy.service";
import { CreatePolicyRequest, Policy } from "@repo/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";

const useRestaurantPolicyCreate = (id: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Policy, Error, { id: string; data: CreatePolicyRequest }>({
    mutationKey: ["createRestaurantPolicy", id],
    mutationFn: ({ id, data }) => PolicyService.createRestaurantPolicy(id, data),
    onSuccess: (data) => {
      toast(policyCreateSuccess(`'${data.displays[0]?.title || "New"}' policy successfully added to restaurant`));
      return queryClient.invalidateQueries({ queryKey: ["restaurantPolicies", id] });
    },
    onError: (error) => {
      console.error(error);
      toast(policyCreateFail);
    },
  });
};

export { useRestaurantPolicyCreate };
