import { ConfirmationDialog } from '@/components/confirmation-dialog';
import RHFTextInput from '@/components/form/rhf-text-input';
import LoadingSpinner from '@/components/loading-spinner';
import Heading from '@/components/typography/heading';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ScrollArea } from '@ui/components';
import { X } from 'lucide-react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import {useEventCreate} from "@/hooks/useEventCreate";
import {CreateEventRequest} from "@repo/types";
import RHFTextArea from "@/components/form/rhf-text-area";

const formSchema = z.object({
    name: z.string(),
    description: z.string(),
});

export default function CreateNewEvent() {
    const params = useParams();
    const navigate = useNavigate();

    if (!params.restaurantSlug) {
        throw Error(`CreateNewEvent requires a restaurantSlug url param`);
    }

    const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
    const createEventMutation = useEventCreate(restaurantQuery?.data?.id || '' );

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: '',
            description: '',
        },
    });

    // Block navigating elsewhere when form has unsaved changes
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return (
            !form.formState.isSubmitting &&
            form.formState.isDirty &&
            currentLocation.pathname !== nextLocation.pathname
        );
    });


    const handleClose = () => {
        // TODO: check if form state is dirty
        const newPath = `/${params.restaurantSlug}/special-events`;
        navigate(newPath);
    };

    const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (values) => {
        const result = await createEventMutation?.mutateAsync({
            data: {
                name: values.name,
                condition: {
                    value: {},
                    weight: 1,
                },
                displays: [
                    {
                        title: values.name,
                        description: values.description,
                        location: 'table',
                        icon: '',
                    },
                    {
                        title: values.name,
                        description: values.description,
                        location: 'checkout',
                        icon: '',
                    },
                    {
                        title: values.name,
                        description: values.description,
                        location: 'email',
                        icon: '',
                    },
                ],
            } as CreateEventRequest
        });
        if (result?.['id']) {
            form.reset()
            const newPath = `/${params.restaurantSlug}/special-events/${result?.['id']}`;
            navigate(newPath);
        }
    };

    return (
        <>
            <div className="flex flex-col h-full">
                <header className="flex justify-between px-5 py-3 border-b">
                    <Heading>Create New Event</Heading>
                    <button onClick={handleClose}>
                        <X />
                    </button>
                </header>

                <FormProvider {...form}>
                    <ScrollArea className="flex-1">
                        <form id="create-event-form" onSubmit={form.handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-4 px-5 py-3">
                                <RHFTextInput label="Display Name" form={form} name="name" />
                                <RHFTextArea label={'Description'} form={form} name="description"  />
                            </div>
                        </form>
                    </ScrollArea>
                </FormProvider>

                <footer className="p-3 border-t-[1px]">
                    <Button
                        form="create-event-form"
                        type="submit"
                        className="w-full"
                        disabled={!form.formState.isDirty || form.formState.isSubmitting}
                    >
                        {form.formState.isSubmitting ? <LoadingSpinner /> : 'Confirm'}
                    </Button>
                </footer>
            </div>

            {blocker.state === 'blocked' ? (
                <ConfirmationDialog
                    open={blocker.state === 'blocked'}
                    title="You have unsaved changes!"
                    description={`Are you sure you want to leave? Your changes will not be saved.`}
                    cancelLabel="Cancel"
                    continueLabel="Discard Changes"
                    onContinue={() => blocker.proceed()}
                    onCancel={() => blocker.reset()}
                />
            ) : null}
        </>
    );
}
