import { format, isAfter, isBefore, parse } from 'date-fns';

export const fullDateToShortDate = (date: string) => {
  const parsedDate = parse(date, 'MMMM dd, yyyy', new Date());
  return format(parsedDate, 'yyyy-MM-dd');
};

export const jsDateToFullDate = (date: Date) => {
  return format(date, 'MMMM dd, yyyy');
};

export const isDateInPast = (date: string) => {
  const parsedDate = parse(date, 'MMMM dd, yyyy', new Date());
  const today = new Date();

  // Set the time of today to 00:00:00 to ignore time part in comparison
  today.setHours(0, 0, 0, 0);

  return isBefore(parsedDate, today);
};

export const isDateInFuture = (date: string) => {
  const parsedDate = parse(date, 'MMMM dd, yyyy', new Date());
  const today = new Date();

  // Set the time of today to 00:00:00 to ignore time part in comparison
  today.setHours(0, 0, 0, 0);

  return isAfter(parsedDate, today);
};
