import SubHeading from '@/components/typography/sub-heading';
import { useRestaurantPolicies, useTablePolicies } from '@/hooks/usePolicies';
import { Policy, PolicyType, PolicyWithCutoff } from '@repo/types';
import { ScrollArea } from '@ui/components';
import { useEffect, useState } from 'react';
import PolicySection from './policy-section';

interface SettingsFeesProps {
  restaurantId: string;
  tableId?: string;
}

export default function SettingsFees({ restaurantId, tableId = undefined }: SettingsFeesProps) {
  const { data: restaurantPolicies } = useRestaurantPolicies(restaurantId);
  const { data: tablePolicies } = useTablePolicies(restaurantId, tableId);

  const [policies, setPolicies] = useState<Policy[] | PolicyWithCutoff[]>([]);
  const [selectionFeePolicies, setSelectionFeePolicies] = useState<Policy[]>([]);
  const [depositFeePolicies, setDepositFeePolicies] = useState<Policy[]>([]);
  const [minSpendPolicies, setMinSpendPolicies] = useState<Policy[]>([]);
  const [noShowPolicies, setNoShowPolicies] = useState<Policy[]>([]);
  const [gratuityPolicies, setGratuityPolicies] = useState<Policy[]>([]);

  const handleAddPolicy = (newPolicy: Policy | PolicyWithCutoff) => {
    setPolicies([...policies, newPolicy]);
  };

  const handleRemovePolicy = (id: string) => {
    setPolicies(policies.filter((policy) => policy.id !== id));
  };

  useEffect(() => {
    if (!restaurantPolicies && !tablePolicies) {
      setPolicies([]);
    } else {
      if (tableId) {
        setPolicies([...(restaurantPolicies || []), ...(tablePolicies || [])]);
      } else {
        setPolicies([...(restaurantPolicies || [])]);
      }
    }
  }, [restaurantPolicies, tableId, tablePolicies]);

  useEffect(() => {
    setSelectionFeePolicies(
      policies.filter((policy) => policy.type === PolicyType.SELECTION_FEE) as Policy[],
    );
    setDepositFeePolicies(policies.filter((policy) => policy.type === PolicyType.DEPOSIT) as Policy[]);
    setMinSpendPolicies(policies.filter((policy) => policy.type === PolicyType.MIN_SPEND) as Policy[]);
    setNoShowPolicies(policies.filter((policy) => policy.type === PolicyType.NO_SHOW) as Policy[]);
    setGratuityPolicies(policies.filter((policy) => policy.type === PolicyType.GRATUITY) as Policy[]);
  }, [policies, tableId]);

  return (
    <ScrollArea className="flex-1 p-3">
      <div className="flex flex-col gap-4 py-4">
        <SubHeading>Fees</SubHeading>

        <PolicySection
          policies={selectionFeePolicies}
          onAddPolicy={handleAddPolicy}
          onRemovePolicy={handleRemovePolicy}
          type={PolicyType.SELECTION_FEE}
          restaurantId={restaurantId}
          tableId={tableId}
        />

        <PolicySection
          policies={depositFeePolicies}
          onAddPolicy={handleAddPolicy}
          onRemovePolicy={handleRemovePolicy}
          type={PolicyType.DEPOSIT}
          restaurantId={restaurantId}
          tableId={tableId}
        />

        <PolicySection
          policies={minSpendPolicies}
          onAddPolicy={handleAddPolicy}
          onRemovePolicy={handleRemovePolicy}
          type={PolicyType.MIN_SPEND}
          restaurantId={restaurantId}
          tableId={tableId}
        />

        <PolicySection
          policies={noShowPolicies}
          onAddPolicy={handleAddPolicy}
          onRemovePolicy={handleRemovePolicy}
          type={PolicyType.NO_SHOW}
          restaurantId={restaurantId}
          tableId={tableId}
        />

        <PolicySection
          policies={gratuityPolicies}
          onAddPolicy={handleAddPolicy}
          onRemovePolicy={handleRemovePolicy}
          type={PolicyType.GRATUITY}
          restaurantId={restaurantId}
          tableId={tableId}
        />
      </div>
    </ScrollArea>
  );
}
