import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, Slider } from "@ui/components";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

interface RHFSlider<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  max?: number;
  step?: number;
  label: string;
  description?: string;
  isPercentage?: boolean;
}

export default function RHFSlider<TFieldValues extends FieldValues>({
  form,
  name,
  max = 100,
  step = 1,
  label,
  description,
  isPercentage,
}: RHFSlider<TFieldValues>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className='flex items-center space-x-4'>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Slider
              min={0}
              max={max}
              step={step}
              value={[field.value]}
              defaultValue={[field.value]}
              onValueChange={(vals) => {
                field.onChange(vals[0]);
              }}
            />
          </FormControl>
          <FormDescription>{isPercentage ? `${(field.value * 100).toFixed(0)}%` : field.value}</FormDescription>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
