import WithTooltip from "@/components/with-tooltip";
import React from "react";

interface TruncateTextProps {
  text: string;
  maxLength: number;
  ellipsis?: string;
}

const TruncateText: React.FC<TruncateTextProps> = ({ text, maxLength, ellipsis = "..." }) => {
  if (text.length <= maxLength) {
    return <>{text}</>;
  }

  const truncatedText = text.slice(0, maxLength) + ellipsis;

  return (
    <WithTooltip content={text}>
      <span>{truncatedText}</span>
    </WithTooltip>
  );
};

export default TruncateText;
