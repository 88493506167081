import { generalUpdateFail, generalUpdateSuccess } from '@/components/toasts';
import TableService from '@/services/table.service';
import { Table, UpdateTableDto } from '@repo/types';
import { useToast } from '@repo/ui';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const useTableUpdate = (
  options?: UseMutationOptions<
    Table,
    AxiosError,
    { restaurantId: string; tableId: string; data: UpdateTableDto }
  >,
) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Table, AxiosError, { restaurantId: string; tableId: string; data: UpdateTableDto }>({
    mutationKey: ['updateTable'],
    mutationFn: ({ restaurantId, tableId, data }) => TableService.update({ restaurantId, tableId, data }),
    onSuccess: (data) => {
      toast(generalUpdateSuccess(`Saved changes to ${data.name}`));
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['table', data.id] }),
        queryClient.invalidateQueries({ queryKey: ['tables', data.restaurantId] }),
      ]);
    },
    onError: (error) => {
      console.error(error);
      toast(generalUpdateFail);
    },
    ...options,
  });
};

export { useTableUpdate };
