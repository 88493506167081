import {adminApiClient} from "@/helpers/api.client";
import { Event, CreateEventRequest, UpdateEventRequest } from "@repo/types";

const findAllRestaurantEvents = async (restaurantId: string) => {
    const response = await adminApiClient.get<Event[]>(
        `/restaurants/${restaurantId}/events`
    );
    return response.data;
};

const createRestaurantEvent = async (
    restaurantId: string,
    event: CreateEventRequest
) => {
    const response = await adminApiClient.post<Event>(
        `/restaurants/${restaurantId}/events`,
        event
    );
    return response.data;
};

const updateRestaurantEvent = async (
    restaurantId: string,
    event: UpdateEventRequest
) => {
    const response = await adminApiClient.patch<Event>(
        `/restaurants/${restaurantId}/events/${event.id}`,
        event
    );
    return response.data;
};

const deleteEvent = async (
    restaurantId: string,
    eventId: string
) => {
    const response = await adminApiClient.delete(
        `/restaurants/${restaurantId}/events/${eventId}`
    );
    return response.data;
};

export default {
    findAllRestaurantEvents,
    createRestaurantEvent,
    updateRestaurantEvent,
    deleteEvent
};