import LoadingSpinner from '@/components/loading-spinner';
import PageHeader from '@/components/page-header';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { Button, Card } from '@ui/components';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { useEvents } from '@/hooks/useEvents';
import { EventList } from "@/components/events-list";
import { Event } from '@repo/types';

export default function SpecialEvents() {
    const params = useParams();
    const location = useLocation();
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    if (!params.restaurantSlug) {
        throw Error('Could not find restaurantSlug url param');
    }

    const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
    const eventsQuery = useEvents(restaurantQuery.data?.id || '');

    useEffect(() => {
        setIsSidebarVisible(!!params.eventId || location.pathname.endsWith('/create'));
    }, [location.pathname, params.eventId]);

    if (restaurantQuery.isLoading || eventsQuery.isLoading) {
        return <LoadingSpinner />;
    }

    if (restaurantQuery.isError || !restaurantQuery.data) {
        return <div>Restaurant not found</div>;
    }

    return (
        <div className="flex flex-1 gap-2">
            <Card className="flex-1 w-full h-full">
                <main className="h-full overflow-y-auto">
                    <div className="flex items-center justify-between">
                        <PageHeader title="Special Events" />
                        <div className="flex gap-2 px-5">
                            <Button asChild>
                                <Link to="create">Create New</Link>
                            </Button>
                        </div>
                    </div>
                    <EventList events={eventsQuery.data as Event[] || []} restaurant={restaurantQuery.data} />
                </main>
            </Card>

            <AnimatePresence>
                {isSidebarVisible && (
                    <motion.div
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: 375 }}
                        exit={{ opacity: 0, width: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Card className="w-full h-full">
                            {/* Outlet is the drawer that opens when selecting an item from the list */}
                            <Outlet />
                        </Card>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
