import { generalUpdateFail, generalUpdateSuccess } from '@/components/toasts';
import TableService from '@/services/table.service';
import { Table, UpdateTableDto } from '@repo/types';
import { useToast } from '@repo/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useTableBatchUpdate = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Partial<Table>[], Error, { restaurantId: string; data: UpdateTableDto[] }>({
    mutationKey: ['batchUpdateTables'],
    mutationFn: ({ restaurantId, data }) => TableService.batchUpdate({restaurantId, data}),
    onSuccess: () => {
      toast(generalUpdateSuccess('Successfully updated tables'));
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['tables'] }),
      ]);
    },
    onError: (error) => {
      console.error(error);
      toast(generalUpdateFail);
    },
  });
};

export { useTableBatchUpdate };
