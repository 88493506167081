import LogoFull from '@/components/logo-full';
import LogoSmall from '@/components/logo-small';
import RestaurantSelector from '@/components/restaurant-selector';
import UserMenu from '@/components/user-menu';
import WithTooltip from '@/components/with-tooltip';
import { restaurantNavConfig, settingsNavConfig } from '@/config/nav-config';
import { ChevronsLeft, ChevronsRight } from 'lucide-react';
import { useParams } from 'react-router-dom';
import NavLinks from './nav-links';

export default function SidebarLeft({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const params = useParams();
  const restaurantSlug = params.restaurantSlug;

  return (
    <aside className="flex flex-col justify-between h-full px-3 py-5 space-y-4" id="sidebar">
      <div className={`flex justify-between ${isExpanded ? 'flex-row' : 'flex-col gap-2'}`}>
        {isExpanded ? <LogoFull /> : <LogoSmall />}
        <WithTooltip content={isExpanded ? 'Collapse' : 'Expand'}>
          <button onClick={() => setIsExpanded(!isExpanded)} className="flex items-center justify-center">
            {isExpanded ? <ChevronsLeft /> : <ChevronsRight />}
          </button>
        </WithTooltip>
      </div>

      {restaurantSlug && (
        <div className="flex flex-col justify-between flex-1">
          <NavLinks links={settingsNavConfig(restaurantSlug)} isExpanded={isExpanded} />
          <NavLinks
            title="Configurations"
            links={restaurantNavConfig(restaurantSlug)}
            isExpanded={isExpanded}
          />
        </div>
      )}

      <div className={`flex gap-2 ${isExpanded ? 'flex-row' : 'flex-col'}`}>
        <UserMenu />
        <RestaurantSelector isExpanded={isExpanded} />
      </div>
    </aside>
  );
}
