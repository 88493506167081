import { generalUpdateSuccess } from '@/components/toasts';
import RestaurantService from '@/services/restaurant.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@ui/components';

const useSyncImages = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['syncImages'],
    mutationFn: (restaurantId: string) => RestaurantService.syncImages(restaurantId),
    onSuccess: () => {
      toast(generalUpdateSuccess('Images synced successfully'));
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['getRestaurantBySlug']}),
      ])
    },
    onError: (error) => {
      console.error(error);
      toast({ title: 'Failed to sync images', variant: 'destructive'});
    },
  });
};

export { useSyncImages };
