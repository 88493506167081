import { AlternativePlatform } from "@repo/types";

const RMSDisplayNameMap: { [key: string]: string } = Object.fromEntries(
  Object.entries(AlternativePlatform).map(([key, value]) => [value, key])
);

export const RMSOptions = Object.values(AlternativePlatform).map((option) => ({
  label: RMSDisplayNameMap[option],
  value: option,
}));


