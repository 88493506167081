import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFSelect from '@/components/form/rhf-select';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import IconWithTooltip from '@/components/icon-with-tooltip';
import { BasePolicyCard } from '@/components/policies/cards';
import {
  CutoffModificationFormData,
  cutoffModificationFormSchema,
} from '@/components/policies/cards/schemas';
import { CutoffUnitOptions } from '@/config/dropdown-options/cutoffs';
import { PolicyDefaultDisclaimerMap } from '@/constants/policies.constants';
import {
  getDayOfWeekCondition,
  getDisplaysArray,
  getHasItemCondition,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  getInitialTitle,
  transformCutoff,
} from '@/helpers/form.helper';
import {
  CreatePolicyRequest,
  CutoffType,
  CutoffUnit,
  DisplayLocation,
  PolicyType,
  PolicyWithCutoff,
  UpdatePolicyRequest,
} from '@repo/types';
import * as React from 'react';

interface CutoffModificationCardProps {
  policy: PolicyWithCutoff;
  maxWeight: number;
}

const CutoffModificationCard: React.FC<CutoffModificationCardProps> = ({ policy, maxWeight }) => {
  const formDefaultValues: CutoffModificationFormData = {
    title: getInitialTitle(policy, 'Modification Cutoff'),
    disclaimer: getInitialDisclaimer(policy, PolicyDefaultDisclaimerMap[policy.type as PolicyType] || ''),
    cutoff: {
      value: policy.cutoff.value || 0,
      unit: policy.cutoff.unit || CutoffUnit.NONE,
      timeOfDay: policy.cutoff.timeOfDay || '',
    },
    displayTable: policy.displays?.some((display) => display.location === DisplayLocation.TABLE),
    displayEmail: policy.displays?.some((display) => display.location === DisplayLocation.EMAIL),
    displayCheckout: policy.displays?.some((display) => display.location === DisplayLocation.CHECKOUT),
    displayOther: policy.displays?.some((display) => display.location === DisplayLocation.OTHER),
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
    },
  };

  const getPolicyCreateData = (formData: CutoffModificationFormData): CreatePolicyRequest => ({
    name: PolicyType.MODIFICATION,
    description: PolicyType.MODIFICATION,
    type: PolicyType.MODIFICATION,
    displays: getDisplaysArray(formData),
    cutoff: transformCutoff(formData.cutoff, CutoffType.MODIFICATION),
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getHasItemCondition(policy),
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: CutoffModificationFormData): UpdatePolicyRequest => ({
    ...policy,
    id: policy.id,
    displays: getDisplaysArray(formData),
    cutoff: transformCutoff(formData.cutoff, CutoffType.MODIFICATION),
    condition: {
      value: {
        ...getDayOfWeekCondition(formData),
        ...getHasItemCondition(policy),
      },
    },
  });

  return (
    <BasePolicyCard<CutoffModificationFormData>
      formSchema={cutoffModificationFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={PolicyType.MODIFICATION}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <PolicyDayOfWeekSelector form={form} />

          <div className="grid gap-4 mb-4 sm:grid-cols-3">
            <RHFTextInput
              form={form}
              name="cutoff.timeOfDay"
              label="Time of Day"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              form={form}
              name="cutoff.value"
              label="Value"
              inputProps={{ type: 'number', min: 0 }}
            />
            <RHFSelect form={form} name="cutoff.unit" label="Unit" options={CutoffUnitOptions} />
          </div>

          <RHFTextInput form={form} name="title" label="Title" canCopyToClipboard />
          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" />

          <div className="grid gap-4 sm:grid-cols-4">
            <RHFSwitch
              form={form}
              name="displayTable"
              label="Table"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayCheckout"
              label="Checkout"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayEmail"
              label="Email"
              className="justify-between p-3 border rounded-md"
            />
            <div className="flex items-center gap-1">
              <RHFSwitch
                form={form}
                name="displayOther"
                label="Other"
                className="justify-between p-3 border rounded-md"
              />
              <IconWithTooltip text='Turn on "Other" to allow displaying this disclaimer in app toasts/popups' />
            </div>
          </div>
        </>
      )}
    </BasePolicyCard>
  );
};

export default CutoffModificationCard;
