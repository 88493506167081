import { TableType } from "./table";

export type DayOfWeek = "mon" | "tues" | "wed" | "thurs" | "fri" | "sat" | "sun";

export const DayOfWeekFullNameMap: Record<DayOfWeek, string> = {
  mon: "Monday",
  tues: "Tuesday",
  wed: "Wednesday",
  thurs: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

export enum AlternativePlatform {
  Auphan = "auphan",
  Bookenda = "bookenda",
  EatApp = "eatapp",
  Infor = "infor",
  JTM = "jtm",
  MrBlack = "mrblack",
  OftenDining = "oftendining",
  OpenTable = "opentable",
  Phone = "phone",
  Email = "email",
  ResDiary = "resdiary",
  Resy = "resy",
  RezPlus = "rezplus",
  SevenRooms = "sevenrooms",
  Snappy = "snappy",
  Tock = "tock",
  TouchBistroDine = "touchbistro",
  WaitlistMe = "waitlistme",
  Yelp = "yelp",
}

export enum Weekday {
  Mon = "mon",
  Tues = "tues",
  Wed = "wed",
  Thurs = "thurs",
  Fri = "fri",
  Sat = "sat",
  Sun = "sun",
}

export enum Timezone {
  EASTERN = "America/New_York",
  CENTRAL = "America/Chicago",
  MOUNTAIN = "America/Denver",
  PACIFIC = "America/Los_Angeles",
  GULF = "Asia/Dubai",
}

export enum ItemType {
  Table = "table",
  AddOn = "addon",
  Experience = "experience",
  Restaurant = "restaurant",
  Invoice = "invoice",
  StripeFee = "stripeFee",
  Refund = "refund",
  Deposit = "deposit",
  Coupon = "coupon",
}

export enum PartyType {
  RESTAURANT = "restaurant",
  USER = "user",
  TK = "tk",
  TAX = "tax",
  STRIPE = "stripe",
}

export enum StripeRegion {
  CANADA = "can",
  USA = "usa",
}

export enum Country {
  CANADA = "Canada",
  USA = "United States",
  UAE = "United Arab Emirates",
}

export enum Currency {
  CAD = "cad",
  USD = "usd",
  AED = "aed",
}

export enum CutoffType {
  HORIZON = "horizon",
  CREATION = "creation",
  CANCELLATION = "cancellation",
  MODIFICATION = "modification",
}

export enum CutoffUnit {
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
  YEARS = "years",
  NONE = "none",
}

export enum DisplayLocation {
  CHECKOUT = "checkout",
  TABLE = "table",
  EMAIL = "email",
  OTHER = "other",
}

export type TimeBefore = {
  margin?: number; // minimum time before, in seconds
  time?: string; // absolute time of day, as HH:MM (24-hour format)
  weekday?: Weekday;
};

export type ClosedDate = {
  date: string;
  description: string;
};
