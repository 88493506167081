import { Form } from "@ui/components";
import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

interface FormWrapperProps<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  onSubmit: (values: TFieldValues) => void;
  formRef: React.RefObject<HTMLFormElement>;
  children: React.ReactNode;
}

export default function FormWrapper<TFieldValues extends FieldValues>({
  form,
  onSubmit,
  formRef,
  children,
}: FormWrapperProps<TFieldValues>) {
  return (
    <Form {...form}>
      <form  className="w-full h-full" onSubmit={form.handleSubmit(onSubmit)} ref={formRef}>{children}</form>
    </Form>
  );
}
