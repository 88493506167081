import { useAuth } from "@/hooks/useAuth";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && !user) {
      navigate("/sign-in", { state: { from: location }});
    }
  }, [user, loading, navigate, location]);

  if (loading) {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
