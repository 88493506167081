import { capitalized } from '@/helpers/common.helper';
import { CommandEmpty, CommandInput } from '@ui/components';
import { CirclePlus } from 'lucide-react';
import { ControllerRenderProps, FieldValues, Path, PathValue } from 'react-hook-form';

interface CustomValueInputProps<TFieldValues extends FieldValues> {
  placeholder?: string;
  field: ControllerRenderProps<TFieldValues>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  handleSelect: (
    value: PathValue<TFieldValues, Path<TFieldValues>>,
    field: ControllerRenderProps<TFieldValues>,
  ) => void;
}

export function CustomValueInput<TFieldValues extends FieldValues>({
  placeholder,
  query,
  setQuery,
  field,
  handleSelect,
}: CustomValueInputProps<TFieldValues>) {
  return (
    <>
      <CommandInput
        placeholder={placeholder ?? 'Search...'}
        value={query}
        onValueChange={(value: string) => setQuery(value)}
      />
      <CommandEmpty
        onClick={() => {
          handleSelect(capitalized(query) as PathValue<TFieldValues, Path<TFieldValues>>, field);
          setQuery('');
        }}
        className="flex cursor-pointer items-center justify-center gap-1 italic"
      >
        <CirclePlus className="h-[1.2rem] w-[1.2rem] transition-all dark:rotate-0 dark:scale-100" />
        {/* <p>Create: </p> */}
        <p className="block max-w-48 truncate font-semibold text-primary">{query}</p>
      </CommandEmpty>
    </>
  );
}
