import { Policy } from "@repo/types";

export function groupPoliciesByName(policies?: Policy[]): Record<string, { description: string; policies: Policy[] }> {
  if (!policies) {
    return {};
  }
  return policies.reduce(
    (acc, policy) => {
      if (!acc[policy.name]) {
        acc[policy.name] = { description: policy.description, policies: [] };
      }
      acc[policy.name].policies.push(policy);
      return acc;
    },
    {} as Record<string, { description: string; policies: Policy[] }>
  );
}
