import WithTooltip from '@/components/with-tooltip';
import { EditModelSettingsForm } from '@/pages/matterport-models/edit/edit-model-settings';
import { Button, Input, Label } from '@ui/components';
import { X } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';

type BetweenTimesInputProps = {
  form: UseFormReturn<EditModelSettingsForm>;
};

export default function BetweenTimesInput({ form }: BetweenTimesInputProps) {
  return (
    <div className="flex w-full gap-2">
      <div className="flex flex-col flex-1 gap-2">
        <Label htmlFor="start-time">Start</Label>
        <Input
          id="start-time"
          type="time"
          value={form.watch('condition.and')?.[0]?.betweenTimes?.[0] || ''}
          onChange={(event) => {
            const andCondition = form.getValues(`condition.and`);
            const endTime = andCondition?.[0]?.betweenTimes?.[1] || '';
            form.setValue(
              'condition.and.0',
              { betweenTimes: [event.target.value, endTime] },
              { shouldDirty: true },
            );
          }}
        />
      </div>

      <div className="flex flex-col flex-1 gap-2">
        <Label htmlFor="end-time">End</Label>
        <Input
          id="end-time"
          type="time"
          value={form.watch('condition.and')?.[0]?.betweenTimes?.[1] || ''}
          onChange={(event) => {
            const andCondition = form.getValues(`condition.and`);
            const startTime = andCondition?.[0]?.betweenTimes?.[0] || '';
            form.setValue(
              'condition.and.0',
              { betweenTimes: [startTime, event.target.value] },
              { shouldDirty: true },
            );
          }}
        />
      </div>

      <WithTooltip content="Clear time condition">
        <Button
          variant="outline"
          size="icon"
          type="button"
          className="self-end"
          onClick={() => form.setValue('condition.and', undefined, { shouldDirty: true })}
        >
          <X />
        </Button>
      </WithTooltip>
    </div>
  );
}
