import { CommandEmpty, CommandInput } from "@ui/components";

export function ReadOnlyInput() {
  return (
    <>
      <CommandInput placeholder="Search..." />
      <CommandEmpty>No result found.</CommandEmpty>
    </>
  );
}
