import LoadingSpinner from "@/components/loading-spinner";
import { imageUploadFail, imageUploadSuccess, } from "@/components/toasts";
import { useMediaUpload } from "@/hooks/useMediaUpload";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormItem,
  FormLabel,
  Input,
  useToast
} from "@ui/components";
import { X } from "lucide-react";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface FileUploadProps {
  label: string;
  description?: string;
  onFilesChange: (files: File[]) => void;
  collection: 'Tables' | 'Events';
}

const FileUpload: React.FC<FileUploadProps> = ({ label, description, onFilesChange, collection }) => {
  const [filePreviews, setFilePreviews] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const { toast } = useToast();

  const params = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: uploadMedia, isPending } = useMediaUpload();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      onFilesChange(files);

      const newPreviews = files.map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise<string>((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
        });
      });
      Promise.all(newPreviews).then((previews) => {
        setFilePreviews((prevPreviews) => [...prevPreviews, ...previews]);
      });
    }
  };

  const handleUpload = async () => {
    try {
      const uploadPromises = selectedFiles.map((file, index) =>
        uploadMedia({
          data: filePreviews[index],
          collection: collection,
          docId: params.pinId! || params.eventId! || '',
          name: file.name,
        })
      );
      await Promise.all(uploadPromises);

      clearFileInputValues();
      toast(imageUploadSuccess);
    } catch (error) {
      console.error(error);
      toast(imageUploadFail);
    }
  };

  const clearFileInputValues = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setFilePreviews([]);
    setSelectedFiles([]);
  };

  return (
    <FormItem>
      <FormLabel>{label}</FormLabel>

      <div className='flex gap-2'>
        <Input
          className='cursor-pointer'
          type='file'
          accept='image/*'
          multiple
          onChange={handleFileChange}
          ref={inputRef}
        />
        {filePreviews.length > 0 && (
          <Button variant='outline' className='flex items-center gap-1' onClick={clearFileInputValues}>
            <X size={14} /> Clear items
          </Button>
        )}
        <Button variant='secondary' onClick={handleUpload} disabled={isPending || !selectedFiles.length} type='button'>
          {isPending ? <LoadingSpinner /> : "Upload"}
        </Button>
      </div>

      {description && <div className='form-description'>{description}</div>}

      {filePreviews.length > 0 && (
        <Card className='w-full'>
          <CardHeader className='flex-row items-center justify-between p-2'>
            <p>
              Preview <span className='text-muted-foreground'>(items to upload)</span>
            </p>
          </CardHeader>
          <CardContent className='flex flex-wrap gap-4 p-2'>
            {filePreviews.map((preview, index) => (
              <Card key={`${preview}-${index}`} className='w-[80px] h-[100px]'>
                <CardContent className='w-full h-full p-1'>
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index}`}
                    className='object-contain w-full h-full rounded-md'
                  />
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Card>
      )}
    </FormItem>
  );
};

export default FileUpload;
