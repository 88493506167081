import { adminApiClient } from '@/helpers/api.client';
import { Table, UpdateTableDto } from '@repo/types';

const findAllByRestaurant = async (restaurantId: string, includeDeleted: boolean) => {
  const response = await adminApiClient.get<Table[]>(
    `/restaurants/${restaurantId}/tables?includeDeleted=${includeDeleted}`,
  );
  return response.data;
};

const findOne = async ({ restaurantId, tableId }: { restaurantId: string; tableId: string }) => {
  const response = await adminApiClient.get<Table>(`/restaurants/${restaurantId}/tables/${tableId}`);
  return response.data;
};

const update = async ({
  restaurantId,
  tableId,
  data,
}: {
  restaurantId: string;
  tableId: string;
  data: UpdateTableDto;
}) => {
  const response = await adminApiClient.patch<Table>(`/restaurants/${restaurantId}/tables/${tableId}`, data);
  return response.data;
};

const batchUpdate = async ({ restaurantId, data }: { restaurantId: string; data: UpdateTableDto[] }) => {
  const response = await adminApiClient.patch<Table[]>(
    `/restaurants/${restaurantId}/tables/batch-update`,
    data,
  );
  return response.data;
};

const create = async ({ restaurantId, data }: { restaurantId: string; data: Partial<Table> }) => {
  const response = await adminApiClient.post<Table>(`/restaurants/${restaurantId}/tables`, data);
  return response.data;
};

const TableService = {
  findAllByRestaurant,
  findOne,
  update,
  batchUpdate,
  create,
};

export default TableService;
