import { Badge, Calendar } from '@ui/components';
import { CircleX } from 'lucide-react';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';

type DaysOfWeekInputProps<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  handleSelectedDatesChange: (dates: Date[] | undefined) => void;
};

export default function DatesInput<TFieldValues extends FieldValues>({
  form,
  name,
  handleSelectedDatesChange,
}: DaysOfWeekInputProps<TFieldValues>) {
  return (
    <div className="flex justify-center w-full">
      <Controller
        name={name}
        control={form.control}
        render={({ field }) => {
          const selectedDates: Date[] = field.value
            ? field.value.map((dateString: string) => new Date(dateString))
            : [];
          // Sort the selectedDates array in ascending order
          const sortedDates = [...selectedDates].sort((a, b) => a.getTime() - b.getTime());

          const handleRemoveDate = (dateToRemove: Date) => {
            const updatedDates = selectedDates.filter((date) => date.getTime() !== dateToRemove.getTime());
            handleSelectedDatesChange(updatedDates);
          };

          return (
            <div className="flex flex-col items-center">
              <Calendar
                mode="multiple"
                fromDate={new Date()}
                selected={selectedDates}
                onSelect={(dates) => handleSelectedDatesChange(dates)}
              />
              <div className="flex flex-wrap self-start gap-2 mt-4">
                {sortedDates.map((date, index) => {
                  const isPastDate = date < new Date();

                  return (
                    <Badge
                      key={index}
                      variant="secondary"
                      className={`flex items-center justify-between gap-2 h-[28px] text-xs ${isPastDate ? 'opacity-25' : ''}`}
                    >
                      <span>
                        {date
                          .toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: '2-digit',
                          })
                          .replace(/\//g, '-')}
                      </span>
                      <button type="button" disabled={isPastDate} onClick={() => handleRemoveDate(date)}>
                        <CircleX size={16} />
                      </button>
                    </Badge>
                  );
                })}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
