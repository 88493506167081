import { useAuth } from '@/hooks/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRootMessage,
  Icons,
  Input,
} from '@ui/components';
import { cn } from '@ui/lib/utils';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const FORM_SCHEMA = z.object({
  email: z
    .string()
    .min(2, {
      message: 'Username must be at least 2 characters.',
    })
    .email(),
  password: z.string().min(4),
});

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const form = useForm<z.infer<typeof FORM_SCHEMA>>({
    resolver: zodResolver(FORM_SCHEMA),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { isSubmitting, isValid } = form.formState;

  async function onSubmit(data: z.infer<typeof FORM_SCHEMA>) {
    return signIn(data.email, data.password)
      .then((userCredential) => {
        if (userCredential?.uid) {
          // Get the intended route from state or default to root
          const pathAfterLogin = location.state?.from?.pathname || '/';
          navigate(pathAfterLogin, { replace: true });
        } else {
          throw new Error('Invalid user name or password');
        }
      })
      .catch((error) => {
        form.setError('root', {
          type: 'custom',
          message: error?.message,
        });
      });
  }

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid gap-2">
            <div className="grid gap-1">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="example@email.com" {...field} autoComplete="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid gap-1">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input placeholder="***" {...field} type="password" autoComplete="current-password" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormRootMessage />
            <Button type="submit" disabled={isSubmitting || !isValid}>
              {isSubmitting && <Icons.spinner className="w-4 h-4 mr-2 animate-spin" />}
              Sign In with Email
            </Button>
          </div>
        </form>
        <FormMessage />
      </Form>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
      </div>
    </div>
  );
}
