import Age21Plus from '@/assets/icons/age-21-plus.svg?react';
import CircleArrowIn from '@/assets/icons/circle-arrow-in.svg?react';
import ForkKnife from '@/assets/icons/fork-knife.svg?react';
import Shirt from '@/assets/icons/shirt.svg?react';
import LightningCloud from '@/assets/icons/lightning-cloud.svg?react';
import Beach from '@/assets/icons/beach.svg?react';
import Waterfront from '@/assets/icons/waterfront.svg?react';
import EntranceFee from '@/assets/icons/entrance-fee.svg?react';

export type IconsaxIcons = (typeof iconsaxIcons)[number];
export type CustomIcons = (typeof customIcons)[number];

// https://iconsax-react.pages.dev/
const iconsaxIcons = [
  'I24Support',
  'I3dCubeScan',
  'I3dRotate',
  'I3dSquare',
  'I3dcube',
  'I3square',
  'Activity',
  'AddCircle',
  'AddSquare',
  'Add',
  'Additem',
  'Airdrop',
  'AirplaneSquare',
  'Airplane',
  'Airpod',
  'Airpods',
  'Alarm',
  'AlignBottom',
  'AlignHorizontally',
  'AlignLeft',
  'AlignRight',
  'AlignVertically',
  'Aquarius',
  'Archive1',
  'ArchiveAdd',
  'ArchiveBook',
  'ArchiveMinus',
  'ArchiveSlash',
  'ArchiveTick',
  'Archive',
  'ArrangeCircle2',
  'ArrangeCircle',
  'ArrangeSquare2',
  'ArrangeSquare',
  'Arrow2',
  'Arrow3',
  'ArrowBottom',
  'ArrowCircleDown',
  'ArrowCircleLeft',
  'ArrowCircleRight',
  'ArrowDown1',
  'ArrowDown2',
  'ArrowDown',
  'ArrowLeft1',
  'ArrowLeft2',
  'ArrowLeft3',
  'ArrowLeft',
  'ArrowRight1',
  'ArrowRight2',
  'ArrowRight3',
  'ArrowRight',
  'ArrowSquareDown',
  'ArrowSquareLeft',
  'ArrowSquareRight',
  'ArrowSquareUp',
  'ArrowSquare',
  'ArrowSwapHorizontal',
  'ArrowUp1',
  'ArrowUp2',
  'ArrowUp3',
  'ArrowUp',
  'Arrow',
  'AttachCircle',
  'AttachSquare',
  'AudioSquare',
  'Autobrightness',
  'Award',
  'BackSquare',
  'Backward10Seconds',
  'Backward15Seconds',
  'Backward5Seconds',
  'BackwardItem1',
  'BackwardItem',
  'Backward',
  'Bag2',
  'BagCross1',
  'BagCross',
  'BagHappy',
  'BagTick2',
  'BagTick',
  'BagTimer',
  'Bag',
  'Bank',
  'Barcode',
  'Battery3full',
  'BatteryCharging',
  'BatteryDisable',
  'BatteryEmpty1',
  'BatteryEmpty',
  'BatteryFull',
  'Bezier1',
  'Bezier',
  'Bill',
  'BitcoinCard',
  'BitcoinConvert',
  'BitcoinRefresh',
  'Blend2',
  'Blend',
  'Bluetooth2',
  'BluetoothCircle',
  'BluetoothRectangle',
  'Bluetooth',
  'Blur1',
  'Blur',
  'Book1',
  'BookSaved',
  'BookSquare',
  'Book',
  'Bookmark2',
  'Bookmark',
  'Box1',
  'Box2',
  'BoxAdd',
  'BoxRemove',
  'BoxSearch',
  'BoxTick',
  'BoxTime',
  'Box',
  'Briefcase',
  'BrifecaseCross',
  'BrifecaseTick',
  'BrifecaseTimer',
  'Broom',
  'Brush1',
  'Brush2',
  'Brush3',
  'Brush',
  'Bubble',
  'BucketCircle1',
  'BucketCircle',
  'BucketSquare1',
  'BucketSquare',
  'Building3',
  'Building4',
  'Building',
  'Buildings2',
  'Buildings',
  'Buliding',
  'Bus',
  'BuyCrypto',
  'Cake',
  'Calculator',
  'Calendar1',
  'Calendar2',
  'CalendarAdd',
  'CalendarCircle',
  'CalendarEdit',
  'CalendarRemove',
  'CalendarSearch',
  'CalendarTick',
  'Calendar',
  'CallAdd',
  'CallCalling',
  'CallIncoming',
  'CallMinus',
  'CallOutgoing',
  'CallReceived',
  'CallRemove',
  'CallSlash',
  'Call',
  'CameraSlash',
  'Camera',
  'Candle2',
  'Candle',
  'Car',
  'CardAdd',
  'CardCoin',
  'CardEdit',
  'CardPos',
  'CardReceive',
  'CardRemove1',
  'CardRemove',
  'CardSend',
  'CardSlash',
  'CardTick1',
  'CardTick',
  'Card',
  'Cards',
  'Category2',
  'Category',
  'Cd',
  'Chart1',
  'Chart2',
  'Chart21',
  'Chart3',
  'ChartSquare',
  'ChartSuccess',
  'Chart',
  'Check',
  'Chrome',
  'ClipboardClose',
  'ClipboardExport',
  'ClipboardImport',
  'ClipboardText',
  'ClipboardTick',
  'Clipboard',
  'Clock1',
  'Clock',
  'CloseCircle',
  'CloseSquare',
  'CloudAdd',
  'CloudChange',
  'CloudConnection',
  'CloudCross',
  'CloudDrizzle',
  'CloudFog',
  'CloudLightning',
  'CloudMinus',
  'CloudNotif',
  'CloudPlus',
  'CloudRemove',
  'CloudSnow',
  'CloudSunny',
  'Cloud',
  'Code1',
  'CodeCircle',
  'Code',
  'Coffee',
  'Coin1',
  'Coin',
  'ColorSwatch',
  'Colorfilter',
  'ColorsSquare1',
  'ColorsSquare',
  'CommandSquare',
  'Command',
  'Component1',
  'Component',
  'Computing',
  'Convert3dCube',
  'Convert',
  'Convertshape2',
  'Convertshape',
  'CopySuccess',
  'Copy',
  'Copyright',
  'Courthouse',
  'CpuCharge',
  'CpuSetting',
  'Cpu',
  'CreativeCommons',
  'Crop',
  'Crown1',
  'Crown',
  'Cup',
  'Danger',
  'Data2',
  'Data',
  'Designtools',
  'DeviceMessage',
  'Devices1',
  'Devices',
  'Diagram',
  'Diamonds',
  'DirectDown',
  'DirectInbox',
  'DirectLeft',
  'DirectNormal',
  'DirectNotification',
  'DirectRight',
  'DirectSend',
  'DirectUp',
  'Direct',
  'DirectboxDefault',
  'DirectboxNotif',
  'DirectboxReceive',
  'DirectboxSend',
  'DiscountCircle',
  'DiscountShape',
  'Discover1',
  'Discover',
  'Dislike',
  'Document1',
  'DocumentCloud',
  'DocumentCode2',
  'DocumentCode',
  'DocumentCopy',
  'DocumentDownload',
  'DocumentFavorite',
  'DocumentFilter',
  'DocumentForward',
  'DocumentLike',
  'DocumentNormal',
  'DocumentPrevious',
  'DocumentSketch',
  'DocumentText1',
  'DocumentText',
  'DocumentUpload',
  'Document',
  'DollarCircle',
  'DollarSquare',
  'Driver2',
  'DriverRefresh',
  'Driver',
  'Driving',
  'Drop',
  'Edit2',
  'Edit',
  'Electricity',
  'Element1',
  'Element2',
  'Element3',
  'Element4',
  'ElementEqual',
  'ElementPlus',
  'EmojiHappy',
  'EmojiNormal',
  'EmojiSad',
  'EmptyWalletAdd',
  'EmptyWalletChange',
  'EmptyWalletRemove',
  'EmptyWalletTick',
  'EmptyWalletTime',
  'EmptyWallet',
  'Eraser1',
  'Eraser2',
  'Eraser',
  'Export1',
  'Export2',
  'Export3',
  'Export',
  'ExternalDrive',
  'EyeSlash',
  'Eye',
  'Fatrows',
  'FavoriteChart',
  'FilterAdd',
  'FilterEdit',
  'FilterRemove',
  'FilterSearch',
  'FilterSquare',
  'FilterTick',
  'Filter',
  'FingerCricle',
  'FingerScan',
  'Firstline',
  'Flag2',
  'Flag',
  'Flash1',
  'FlashCircle1',
  'FlashCircle2',
  'FlashCircle',
  'FlashSlash',
  'Flash',
  'Folder2',
  'FolderAdd',
  'FolderCloud',
  'FolderConnection',
  'FolderCross',
  'FolderFavorite',
  'FolderMinus',
  'FolderOpen',
  'Folder',
  'FormatCircle',
  'FormatSquare',
  'Forward10Seconds',
  'Forward15Seconds',
  'Forward5Seconds',
  'ForwardItem1',
  'ForwardItem',
  'ForwardSquare',
  'Forward',
  'Frame1',
  'Frame2',
  'Frame3',
  'Frame4',
  'Frame5',
  'Frame',
  'GalleryAdd',
  'GalleryEdit',
  'GalleryExport',
  'GalleryFavorite',
  'GalleryImport',
  'GalleryRemove',
  'GallerySlash',
  'GalleryTick',
  'Gallery',
  'Game',
  'Gameboy',
  'GasStation',
  'Gemini2',
  'Gemini',
  'Ghost',
  'Gift',
  'Glass1',
  'Glass',
  'GlobalEdit',
  'GlobalRefresh',
  'GlobalSearch',
  'Global',
  'GpsSlash',
  'Gps',
  'Grammerly',
  'Graph',
  'Grid1',
  'Grid2',
  'Grid3',
  'Grid4',
  'Grid5',
  'Grid6',
  'Grid7',
  'Grid8',
  'Grid9',
  'GridEdit',
  'GridEraser',
  'GridLock',
  'Group1',
  'Group10',
  'Group11',
  'Group2',
  'Group3',
  'Group4',
  'Group5',
  'Group6',
  'Group7',
  'Group8',
  'Group9',
  'Group',
  'Happyemoji',
  'Hashtag1',
  'HashtagDown',
  'HashtagUp',
  'Hashtag',
  'Headphone',
  'Headphones',
  'Health',
  'HeartAdd',
  'HeartCircle',
  'HeartEdit',
  'HeartRemove',
  'HeartSearch',
  'HeartSlash',
  'HeartTick',
  'Heart',
  'Hierarchy2',
  'Hierarchy3',
  'HierarchySquare2',
  'HierarchySquare3',
  'HierarchySquare',
  'Hierarchy',
  'Home2',
  'HomeHashtag',
  'HomeTrendDown',
  'HomeTrendUp',
  'HomeWifi',
  'Home',
  'Hospital',
  'House2',
  'House',
  'Image',
  'Import1',
  'Import2',
  'Import',
  'InfoCircle',
  'Information',
  'Instagram',
  'Judge',
  'Kanban',
  'KeySquare',
  'Key',
  'KeyboardOpen',
  'Keyboard',
  'Lamp1',
  'LampCharge',
  'LampOn',
  'LampSlash',
  'Lamp',
  'LanguageCircle',
  'LanguageSquare',
  'Layer',
  'Level',
  'Lifebuoy',
  'Like1',
  'LikeDislike',
  'LikeShapes',
  'LikeTag',
  'Like',
  'Link1',
  'Link2',
  'Link21',
  'LinkCircle',
  'LinkSquare',
  'Link',
  'LocationAdd',
  'LocationCross',
  'LocationMinus',
  'LocationSlash',
  'LocationTick',
  'Location',
  'Lock1',
  'LockCircle',
  'LockSlash',
  'Lock',
  'Login1',
  'Login',
  'Logout1',
  'Logout',
  'Lovely',
  'MagicStar',
  'Magicpen',
  'MainComponent1',
  'MainComponent',
  'Man',
  'Map1',
  'Map',
  'Mask1',
  'Mask2',
  'Mask3',
  'Mask',
  'Math',
  'Maximize1',
  'Maximize2',
  'Maximize21',
  'Maximize3',
  'Maximize4',
  'MaximizeCircle',
  'Maximize',
  'MedalStar',
  'Medal',
  'Menu1',
  'MenuBoard',
  'Menu',
  'Message2',
  'MessageAdd1',
  'MessageAdd',
  'MessageCircle',
  'MessageEdit',
  'MessageFavorite',
  'MessageMinus',
  'MessageNotif',
  'MessageProgramming',
  'MessageQuestion',
  'MessageRemove',
  'MessageSearch',
  'MessageSquare',
  'MessageText1',
  'MessageText',
  'MessageTick',
  'MessageTime',
  'Message',
  'Messages1',
  'Messages2',
  'Messages3',
  'Messages',
  'Microphone2',
  'MicrophoneSlash1',
  'MicrophoneSlash',
  'Microphone',
  'Microscope',
  'Milk',
  'MiniMusicSqaure',
  'MinusCirlce',
  'MinusSquare',
  'Minus',
  'Mirror',
  'MirroringScreen',
  'MobileProgramming',
  'Mobile',
  'Money2',
  'Money3',
  'Money4',
  'MoneyAdd',
  'MoneyChange',
  'MoneyForbidden',
  'MoneyRecive',
  'MoneyRemove',
  'MoneySend',
  'MoneyTick',
  'MoneyTime',
  'Money',
  'Moneys',
  'MonitorMobbile',
  'MonitorRecorder',
  'Monitor',
  'Moon',
  'More2',
  'MoreCircle',
  'MoreSquare',
  'More',
  'Mouse1',
  'MouseCircle',
  'MouseSquare',
  'Mouse',
  'MusicCircle',
  'MusicDashboard',
  'MusicFilter',
  'MusicLibrary2',
  'MusicPlay',
  'MusicPlaylist',
  'MusicSquareAdd',
  'MusicSquareRemove',
  'MusicSquareSearch',
  'MusicSquare',
  'Music',
  'Musicnote',
  'Next',
  'Note1',
  'Note2',
  'Note21',
  'NoteAdd',
  'NoteFavorite',
  'NoteRemove',
  'NoteSquare',
  'NoteText',
  'Note',
  'Notification1',
  'NotificationBing',
  'NotificationCircle',
  'NotificationFavorite',
  'NotificationStatus',
  'Notification',
  'OmegaCircle1',
  'OmegaCircle',
  'OmegaSquare1',
  'OmegaSquare',
  'Paintbucket',
  'Paperclip2',
  'Paperclip',
  'PasswordCheck',
  'Path2',
  'PathSquare',
  'Path',
  'PauseCircle',
  'Pause',
  'PenAdd1',
  'PenAdd',
  'PenClose1',
  'PenClose',
  'PenRemove1',
  'PenRemove',
  'PenTool1',
  'PenTool21',
  'PenTool2',
  'PenTool',
  'People',
  'PercentageSquare',
  'Personalcard',
  'Pet',
  'Pharagraphspacing',
  'PictureFrame',
  'PlayAdd',
  'PlayCircle',
  'PlayCricle',
  'PlayRemove',
  'Play',
  'PresentionChart',
  'Previous',
  'PrinterSlash',
  'Printer',
  'Profile2user',
  'ProfileAdd',
  'ProfileCircle',
  'ProfileDelete',
  'ProfileRemove',
  'ProfileTick',
  'Profile',
  'ProgrammingArrow',
  'ProgrammingArrows',
  'QuoteDownCircle',
  'QuoteDownSquare',
  'QuoteDown',
  'QuoteUpCircle',
  'QuoteUpSquare',
  'QuoteUp',
  'Radar1',
  'Radar2',
  'Radar',
  'Radio',
  'Ram2',
  'Ram',
  'Ranking1',
  'Ranking',
  'Receipt1',
  'Receipt21',
  'Receipt2',
  'ReceiptAdd',
  'ReceiptDiscount',
  'ReceiptDisscount',
  'ReceiptEdit',
  'ReceiptItem',
  'ReceiptMinus',
  'ReceiptSearch',
  'ReceiptSquare',
  'ReceiptText',
  'Receipt',
  'ReceiveSquare2',
  'ReceiveSquare',
  'Received',
  'RecordCircle',
  'Record',
  'RecoveryConvert',
  'Redo',
  'Refresh2',
  'RefreshCircle',
  'RefreshLeftSquare',
  'RefreshRightSquare',
  'RefreshSquare2',
  'Refresh',
  'RepeatCircle',
  'Repeat',
  'RepeateMusic',
  'RepeateOne',
  'Reserve',
  'RotateLeft1',
  'RotateLeft',
  'RotateRight1',
  'RotateRight',
  'RouteSquare',
  'Routing2',
  'Routing',
  'RowHorizontal',
  'RowVertical',
  'Ruler&pen',
  'Ruler',
  'SafeHome',
  'Sagittarius',
  'Save2',
  'SaveAdd',
  'SaveMinus',
  'SaveRemove',
  'ScanBarcode',
  'Scan',
  'Scanner',
  'Scanning',
  'Scissor1',
  'Scissor2',
  'Scissor',
  'Screenmirroring',
  'Scroll',
  'SearchFavorite1',
  'SearchFavorite',
  'SearchNormal1',
  'SearchNormal',
  'SearchStatus1',
  'SearchStatus',
  'SearchZoomIn1',
  'SearchZoomIn',
  'SearchZoomOut1',
  'SearchZoomOut',
  'SecurityCard',
  'SecuritySafe',
  'SecurityTime',
  'SecurityUser',
  'Security',
  'Send1',
  'Send2',
  'SendSqaure2',
  'SendSquare',
  'Send',
  'Setting2',
  'Setting3',
  'Setting4',
  'Setting5',
  'Setting',
  'Settings',
  'Shapes1',
  'Shapes2',
  'Shapes',
  'Share',
  'ShieldCross',
  'ShieldSearch',
  'ShieldSecurity',
  'ShieldSlash',
  'ShieldTick',
  'Shield',
  'Ship',
  'ShopAdd',
  'ShopRemove',
  'Shop',
  'ShoppingBag',
  'ShoppingCart',
  'SidebarBottom',
  'SidebarLeft',
  'SidebarRight',
  'SidebarTop',
  'Signpost',
  'Simcard1',
  'Simcard2',
  'Simcard',
  'Size',
  'Slash',
  'SliderHorizontal1',
  'SliderHorizontal',
  'SliderVertical1',
  'SliderVertical',
  'Slider',
  'Smallcaps',
  'SmartCar',
  'SmartHome',
  'Smileys',
  'SmsEdit',
  'SmsNotification',
  'SmsSearch',
  'SmsStar',
  'SmsTracking',
  'Sms',
  'Sort',
  'Sound',
  'Speaker',
  'Speedometer',
  'Star1',
  'StarSlash',
  'Star',
  'StatusUp',
  'Status',
  'Sticker',
  'Stickynote',
  'StopCircle',
  'Stop',
  'Story',
  'Strongbox2',
  'Strongbox',
  'Subtitle',
  'Sun1',
  'SunFog',
  'Sun',
  'Tag2',
  'TagCross',
  'TagRight',
  'TagUser',
  'Tag',
  'TaskSquare',
  'Task',
  'Teacher',
  'TextBlock',
  'TextBold',
  'TextItalic',
  'TextUnderline',
  'Text',
  'TextalignCenter',
  'TextalignJustifycenter',
  'TextalignJustifyleft',
  'TextalignJustifyright',
  'TextalignLeft',
  'TextalignRight',
  'TickCircle',
  'TickSquare',
  'Ticket2',
  'TicketDiscount',
  'TicketExpired',
  'TicketStar',
  'Ticket',
  'Timer1',
  'TimerPause',
  'TimerStart',
  'Timer',
  'ToggleOffCircle',
  'ToggleOff',
  'ToggleOnCircle',
  'ToggleOn',
  'Trade',
  'TransactionMinus',
  'Translate',
  'Trash',
  'Tree',
  'TrendDown',
  'TrendUp',
  'Triangle',
  'TruckRemove',
  'TruckTick',
  'TruckTime',
  'TrushSquare',
  'Undo',
  'Unlimited',
  'Unlock',
  'UserAdd',
  'UserCirlceAdd',
  'UserEdit',
  'UserMinus',
  'UserOctagon',
  'UserRemove',
  'UserSearch',
  'UserSquare',
  'UserTag',
  'UserTick',
  'User',
  'Verify',
  'VideoAdd',
  'VideoCircle',
  'VideoHorizontal',
  'VideoOctagon',
  'VideoPlay',
  'VideoRemove',
  'VideoSlash',
  'VideoSquare',
  'VideoTick',
  'VideoTime',
  'VideoVertical',
  'Video',
  'VoiceCricle',
  'VoiceSquare',
  'VolumeCross',
  'VolumeHigh',
  'VolumeLow1',
  'VolumeLow',
  'VolumeMute',
  'VolumeSlash',
  'VolumeUp',
  'Wallet1',
  'Wallet2',
  'Wallet3',
  'WalletAdd1',
  'WalletAdd',
  'WalletCheck',
  'WalletMinus',
  'WalletMoney',
  'WalletRemove',
  'WalletSearch',
  'Wallet',
  'Warning2',
  'WatchStatus',
  'Watch',
  'Weight1',
  'Weight',
  'WifiSquare',
  'Wifi',
  'Wind2',
  'Wind',
  'Woman',
] as const;

export const IconsaxIconOptions = iconsaxIcons.map((name) => ({
  value: name,
  label: name,
}));

// eslint-disable-next-line react-refresh/only-export-components
export const customIconMap: Record<CustomIcons, React.ReactNode> = {
  Tablz__Age21Plus: <Age21Plus />,
  Tablz__CircleArrowIn: <CircleArrowIn />,
  Tablz__ForkKnife: <ForkKnife />,
  Tablz__LightningCloud: <LightningCloud />,
  Tablz__Shirt: <Shirt />,
  Tablz__Beach: <Beach />,
  Tablz__EntranceFee: <EntranceFee />,
  Tablz__Waterfront: <Waterfront />,
};

const customIcons = [
  'Tablz__Age21Plus',
  'Tablz__CircleArrowIn',
  'Tablz__ForkKnife',
  'Tablz__LightningCloud',
  'Tablz__Shirt',
  'Tablz__Beach',
  'Tablz__EntranceFee',
  'Tablz__Waterfront',
] as const;

export const CustomIconOptions = customIcons.map((name) => ({
  value: name,
  label: name,
  icon: customIconMap[name],
}));
