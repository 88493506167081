import TableService from '@/services/table.service';
import { Table } from '@repo/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@ui/components';

const useTableCreate = (restaurantId: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Partial<Table>, Error, Partial<Table>>({
    mutationKey: ['createTable'],
    mutationFn: (data) => TableService.create({ restaurantId, data }),
    onSuccess: () => {
      toast({ title: 'Table created', variant: 'success' });
      return Promise.all([queryClient.invalidateQueries({ queryKey: ['tables'] })]);
    },
    onError: (error) => {
      console.error(error);
      toast({ title: 'Failed to create table', variant: 'destructive' });
    },
  });
};

export { useTableCreate };
