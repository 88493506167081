import { policyCreateFail, policyCreateSuccess } from "@/components/toasts";
import {CreateEventRequest } from "@repo/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";
import EventService from "@/services/event.service";
import { Event } from "@repo/types";

const useEventCreate = (restaurantId: string) => {
    const queryClient = useQueryClient();
    const { toast } = useToast();

    return useMutation<Event, Error, { data: CreateEventRequest }>({
        mutationKey: ["createEvent", restaurantId],
        mutationFn: ({ data }) => EventService.createRestaurantEvent(restaurantId, data),
        onSuccess: (data) => {
            toast(policyCreateSuccess(`'${data.displays[0]?.title || "New"}' event successfully added to restaurant`));
            return queryClient.invalidateQueries({ queryKey: ["events", restaurantId] });
        },
        onError: (error) => {
            console.error(error);
            toast(policyCreateFail);
        },
    });
};

export { useEventCreate };

