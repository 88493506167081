import { policyCreateFail } from "@/components/toasts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";
import EventService from "@/services/event.service";
import { Event } from "@repo/types";

const useEventDelete = (restaurantId: string) => {
    const queryClient = useQueryClient();
    const { toast } = useToast();

    return useMutation<Event, Error, { data: {id: string} }>({
        mutationKey: ["deleteEvent", restaurantId],
        mutationFn: ({ data }) => EventService.deleteEvent(restaurantId, data.id),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ["events", restaurantId] });
        },
        onError: (error) => {
            console.error(error);
            toast(policyCreateFail);
        },
    });
};

export { useEventDelete };

