import LoadingSpinner from '@/components/loading-spinner';
import NavLinks from '@/components/nav-links';
import Heading from '@/components/typography/heading';
import { editPinNavConfig } from '@/config/edit-pin-nav.config';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useTable } from '@/hooks/useTable';
import { ScrollArea } from '@ui/components';
import { ChevronLeft, X } from 'lucide-react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export default function EditPinSettings() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  if (!params.pinId) {
    throw Error(`EditPinSettings requires a pinId url param`);
  }

  if (!params.restaurantSlug) {
    throw Error(`EditPinSettings requires a restaurantSlug url param`);
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const restaurantData = restaurantQuery.data;
  const tableQuery = useTable({
    restaurantId: restaurantData!.id,
    tableId: params.pinId,
  });
  const tableData = tableQuery.data;

  const handleClose = () => {
    const newPath = `/${params.restaurantSlug}/matterport-pins`;
    navigate(newPath);
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (tableQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (tableQuery.isError || !tableData || !restaurantData) {
    return <p>ERROR</p>;
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <header className="flex justify-between px-5 py-3 border-b">
          {location.pathname.endsWith(`/${params.pinId}`) ? null : (
            <button onClick={handleBack}>
              <ChevronLeft />
            </button>
          )}
          <Heading>{tableData.name}</Heading>
          <button onClick={handleClose}>
            <X />
          </button>
        </header>

        {location.pathname.endsWith(`/${params.pinId}`) ? (
          <ScrollArea className="flex-1 p-3">
            <NavLinks links={editPinNavConfig} />
          </ScrollArea>
        ) : (
          <Outlet />
        )}
      </div>
    </>
  );
}
