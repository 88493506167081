import { policyUpdateFail, policyUpdateSuccess } from "@/components/toasts";
import PolicyService from "@/services/policy.service";
import { Policy, UpdatePolicyRequest } from "@repo/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";

const useTablePolicyUpdate = (tableId?: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Policy, Error, { restaurantId: string; tableId: string; data: UpdatePolicyRequest }>({
    mutationKey: ["updateTablePolicy", tableId],
    mutationFn: ({ restaurantId, tableId, data }) => PolicyService.updateTablePolicy(restaurantId, tableId, data),
    onSuccess: (data) => {
      toast(policyUpdateSuccess(`'${data.displays[0].title}' policy successfully updated`));
      return queryClient.invalidateQueries({ queryKey: ["tablePolicies", tableId] });
    },
    onError: (error) => {
      console.error(error);
      toast(policyUpdateFail("Failed to update table policy"));
    },
  });
};

export { useTablePolicyUpdate };
