import { PolicyType } from "@repo/types";

export const PolicyLabelMap: Partial<Record<PolicyType, string>> = {
  [PolicyType.BOOKING_LEAD_TIME]: "Lead Time",
  [PolicyType.BOOKING_HORIZON]: "Horizon",
  [PolicyType.GRACE_PERIOD]: "Grace Period",
  [PolicyType.MODIFICATION]: "Modification",
  [PolicyType.CANCELLATION]: "Cancellation",
  [PolicyType.SELECTION_FEE]: "Selection Fee",
  [PolicyType.DEPOSIT]: "Deposit",
  [PolicyType.MIN_SPEND]: "Minimum Spend",
  [PolicyType.NO_SHOW]: "No-Show",
  [PolicyType.GRATUITY]: "Gratuity",
};

export const PolicyDescriptionMap: Partial<Record<PolicyType, string>> = {
  [PolicyType.BOOKING_LEAD_TIME]: "How much notice is needed to make a booking.",
  [PolicyType.BOOKING_HORIZON]: "How far in advance a booking can be made.",
  [PolicyType.GRACE_PERIOD]: "How long the restaurant will hold the table if guest is late.",
  [PolicyType.MODIFICATION]: "How long before the booking that modifications are allowed.",
  [PolicyType.CANCELLATION]: "How long before the booking that cancellations are allowed.",
  [PolicyType.SELECTION_FEE]: "Provides clarification to guest about our table selection fee",
  [PolicyType.DEPOSIT]: "Provides clarification to guest about deposit fees",
  [PolicyType.MIN_SPEND]: "Provides clarification to guest about minimum spend requirements",
  [PolicyType.NO_SHOW]: "Provides clarification to guest about no-show fees charged for failure to attend reservation",
  [PolicyType.GRATUITY]:
    "Provides clarification to guest about gratuity fees that will be automatically added to their bill",
};

export const DEFAULT_POLICY_ICON = "InfoCircle";

export const PolicyIconMap: Partial<Record<PolicyType, string>> = {
  [PolicyType.BOOKING_LEAD_TIME]: "Clock",
  [PolicyType.BOOKING_HORIZON]: "Clock",
  [PolicyType.GRACE_PERIOD]: "Timer1",
  [PolicyType.MODIFICATION]: "CalendarEdit",
  [PolicyType.CANCELLATION]: "Forbidden2",
  [PolicyType.SELECTION_FEE]: "Tablz__SelectionFeeIcon",
  [PolicyType.DEPOSIT]: "MoneyRecive", // the typo here is from iconsax
  [PolicyType.MIN_SPEND]: "Coin1",
  [PolicyType.NO_SHOW]: "CalendarRemove",
  [PolicyType.GRATUITY]: "DollarSquare",
};

export const PolicyDefaultDisclaimerMap: Partial<Record<PolicyType, string>> = {
  [PolicyType.BOOKING_LEAD_TIME]: "Unfortunately, bookings cannot be made less than {{VALUE}} in advance. Please try another date, or search for same-day availability on {{RMS}}.",
  [PolicyType.BOOKING_HORIZON]: "Unfortunately, bookings cannot be made more than {{VALUE}} in advance. Please try another date, or check back in {{##}} days.",
  [PolicyType.GRACE_PERIOD]: "There is a grace period of {{VALUE}} for your scheduled seating. Your booking may not be honored if you arrive past this grace period.",
  [PolicyType.MODIFICATION]: "Modifications can be made up to {{VALUE}} before your scheduled booking.",
  [PolicyType.CANCELLATION]: "Cancellations can be made up to {{VALUE}} before your scheduled booking, for a full refund of your seating selection fee.",
  [PolicyType.SELECTION_FEE]: "The seating selection fee is separate from the food and beverage deposit.",
  [PolicyType.DEPOSIT]: "There is a non-refundable deposit of {{VALUE}}, deducted from the final bill at the restaurant.",
  [PolicyType.MIN_SPEND]: "Your deposit will be deducted from a minimum spend of {{VALUE}} on food and beverage, prior to tax and gratuity.",
  [PolicyType.NO_SHOW]: "Failure to attend your scheduled seating without notifying the restaurant in advance will result in an additional no-show fee of {{$$}} per guest.",
  [PolicyType.GRATUITY]: "There is an auto-gratuity of {{VALUE}} applied to the final bill at the restaurant."
};
