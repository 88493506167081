import { adminApiClient } from '@/helpers/api.client';

const getTagsByModelId = async (modelId: string) => {
  const response = await adminApiClient.get(`/matterport/tags?modelId=${modelId}`);
  return response.data;
};

const MatterportService = { getTagsByModelId };

export default MatterportService;
