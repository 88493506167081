import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/components";

interface WithTooltipProps {
  content?: React.ReactNode;
  children: React.ReactNode;
}

const WithTooltip = ({ content, children }: WithTooltipProps) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      {content && <TooltipContent>{content}</TooltipContent>}
    </Tooltip>
  );
};

export default WithTooltip;
