import WithTooltip from '@/components/with-tooltip';
import { LucideIcon } from 'lucide-react';
import { Link, To } from 'react-router-dom';

export interface ActionItem {
  icon: LucideIcon;
  to?: To;
  onClick?: () => void;
  tooltip?: string;
  external?: boolean;
}

interface DataTableRowActionsProps {
  actions: ActionItem[];
}

export function DataTableRowActions({ actions }: DataTableRowActionsProps) {
  return (
    <div className="flex justify-center p-2 gap-7">
      {actions.map((action, index) => (
        <WithTooltip key={index} content={action.tooltip}>
          {action.external ? (
            <a href={action.to as string} target="_blank" rel="noopener noreferrer" onClick={action.onClick}>
              <action.icon size={16} />
            </a>
          ) : (
            <Link to={action.to as To} onClick={action.onClick}>
              <action.icon size={16} />
            </Link>
          )}
        </WithTooltip>
      ))}
    </div>
  );
}
