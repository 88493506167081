import { CustomValueInput } from '@/components/form/rhf-combobox/custom-value-input';
import { GroupedOptions, OptionGroup } from '@/components/form/rhf-combobox/grouped-options';
import { buildOptions, flattenOptions, isGrouped } from '@/components/form/rhf-combobox/helper';
import { ReadOnlyInput } from '@/components/form/rhf-combobox/read-only-input';
import { SelectOption, SelectOptions } from '@/components/form/rhf-combobox/select-options';
import { cn } from '@repo/ui/lib/utils';
import {
  Badge,
  Button,
  Command,
  CommandGroup,
  CommandList,
  CommandSeparator,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@ui/components';
import { ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';
import { ControllerRenderProps, FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

interface RHFCombobox<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  placeholder?: string;
  options: SelectOption<TFieldValues>[] | OptionGroup<TFieldValues>[];
  description?: string;
  isMulti?: boolean;
  footer?: React.ReactNode;
  allowCustomValues?: boolean;
  disabled?: boolean;
}

export default function RHFCombobox<TFieldValues extends FieldValues>({
  form,
  name,
  label,
  placeholder,
  options: defaultOptions,
  description,
  isMulti = false,
  footer = false,
  allowCustomValues = false,
  disabled = false,
}: RHFCombobox<TFieldValues>) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>('');

  const allOptions = buildOptions(name, defaultOptions, form);

  const handleSelect = (
    value: PathValue<TFieldValues, Path<TFieldValues>>,
    field: ControllerRenderProps<TFieldValues>,
  ) => {
    if (isMulti) {
      const currentValue = field.value || ([] as PathValue<TFieldValues, Path<TFieldValues>>);
      const newValue = currentValue.includes(value)
        ? currentValue.filter((val: string) => val !== value)
        : [...currentValue, value];
      form.setValue(name, newValue, { shouldDirty: true });
    } else {
      form.setValue(name, value, { shouldDirty: true });
      setOpen(false);
    }
  };

  const handleCustomValueSelect = (
    value: PathValue<TFieldValues, Path<TFieldValues>>,
    field: ControllerRenderProps<TFieldValues>,
  ) => {
    handleSelect(value, field);
  };

  const renderOptions = (field: ControllerRenderProps<TFieldValues>) => {
    if (isGrouped(allOptions)) {
      return <GroupedOptions options={allOptions} field={field} handleSelect={handleSelect} />;
    } else {
      return <SelectOptions options={allOptions} field={field} handleSelect={handleSelect} />;
    }
  };

  const renderBadges = (field: ControllerRenderProps<TFieldValues>) => {
    const flattenedOptions = flattenOptions(defaultOptions);
    if (isMulti) {
      if (field.value?.length > 0) {
        return (
          <div className="flex flex-wrap gap-1">
            <Badge variant="secondary">{field.value[0]}</Badge>
            {field.value.length >= 2 ? (
              <Badge variant="secondary">{`${field.value.length - 1} more`}</Badge>
            ) : null}
          </div>
        );
      } else {
        return 'Select options';
      }
    } else {
      if (field.value) {
        const label = flattenedOptions.find((option) => option.value === field.value)?.label || field.value;
        const displayValue = label.length >= 20 ? `${label.substring(0, 21)}...` : label;
        return <span>{displayValue}</span>;
      } else {
        return 'Select option';
      }
    }
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex items-center gap-2">{label}</FormLabel>
          <Popover open={open} onOpenChange={setOpen} modal>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  disabled={disabled}
                  className={cn('w-full justify-between', !field.value && 'text-muted-foreground')}
                >
                  {renderBadges(field)}
                  <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0" align="start">
              <Command>
                {allowCustomValues ? (
                  <CustomValueInput
                    placeholder={placeholder}
                    query={query}
                    setQuery={setQuery}
                    field={field}
                    handleSelect={handleCustomValueSelect}
                  />
                ) : (
                  <ReadOnlyInput />
                )}
                <CommandList>{renderOptions(field)}</CommandList>
                {footer && (
                  <>
                    <CommandSeparator />
                    <CommandGroup>{footer}</CommandGroup>
                  </>
                )}
              </Command>
            </PopoverContent>
          </Popover>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
