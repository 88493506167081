import { CopyToClipboardButton } from "@/components/copy-to-clipboard-button";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Textarea
} from "@ui/components";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

interface RHFTextArea<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  placeholder?: string;
  description?: string;
  canCopyToClipboard?: boolean;
  className?: string;
  textareaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
}

export default function RHFTextArea<TFieldValues extends FieldValues>({
  form,
  name,
  label,
  placeholder,
  description,
  canCopyToClipboard = false,
  className,
  textareaProps
}: RHFTextArea<TFieldValues>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel className='flex items-center gap-2'>
            {label}
            {canCopyToClipboard && <CopyToClipboardButton text={field.value} />}
          </FormLabel>
          <FormControl>
            <Textarea placeholder={placeholder} {...field} {...textareaProps} />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
