import { dayMapping } from '@/constants/days.constants';
import { Label, Switch } from '@ui/components';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';

export type DayMappingValues = (typeof dayMapping)[keyof typeof dayMapping];

type DaysOfWeekInputProps<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  handleSelectedDaysChange: (day: DayMappingValues, checked: boolean) => void;
};

export default function DaysOfWeekInput<TFieldValues extends FieldValues>({
  form,
  name,
  handleSelectedDaysChange,
}: DaysOfWeekInputProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { value } }) => (
        <div className="flex flex-col gap-1">
          {Object.entries(dayMapping).map(([dayName, dayValue]) => (
            <div key={dayValue} className="flex justify-between py-2">
              <Label htmlFor={`${dayValue}-switch`}>{dayName}</Label>
              <Switch
                id={`${dayValue}-switch`}
                checked={value && value.includes(dayValue)}
                onCheckedChange={(checked) => {
                  handleSelectedDaysChange(dayValue, checked);
                }}
              />
            </div>
          ))}
        </div>
      )}
    />
  );
}
