import SidebarLeft from '@/components/sidebar-left';
import { Card, Toaster } from '@ui/components';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export default function MainLayout() {
  const [isLeftSidebarExpanded, setIsLeftSidebarExpanded] = useState(true);

  return (
    <div className="relative flex w-full overflow-hidden dark:bg-black font-inter">
      <div className="relative flex w-full h-screen gap-2 p-2">
        <Card
          className={`relative flex flex-col transition-all duration-300 ${isLeftSidebarExpanded ? 'w-60' : 'w-16'}`}
        >
          <SidebarLeft isExpanded={isLeftSidebarExpanded} setIsExpanded={setIsLeftSidebarExpanded} />
        </Card>

        <Outlet />
      </div>

      <Toaster />
    </div>
  );
}
