import { ThemeProvider } from "@/components/theme-provider";
import { router } from "@/routes";
import { TooltipProvider } from "@repo/ui";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
// import { createPortal } from "react-dom";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { AuthProvider } from "@/components/auth-provider";
import GeneralError from "@/pages/errors/general-error";
import "@repo/ui/main.css";
import "./index.css";
import { queryClient } from "@/helpers/api.client";

Sentry.init({
  enabled: import.meta.env.VITE_NODE_ENV === "production",
  dsn: "https://e6e4f4167a77ca6a270698e3d831aff7@o301373.ingest.us.sentry.io/4507459080290304",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.tablz\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark">
      <Sentry.ErrorBoundary fallback={<GeneralError />}>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <TooltipProvider>
              <RouterProvider router={router} />
            </TooltipProvider>
            {/* {createPortal(<ReactQueryDevtools />, document.body)} */}
          </QueryClientProvider>
        </AuthProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);
