import { fileUploadClient, firebaseFunctionsClient } from '@/helpers/api.client';

export interface UploadMediaDTO {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  name: string;
  collection: string;
  docId: string;
}
const uploadMediaItem = async ({ data, name, collection, docId }: UploadMediaDTO) => {
  const response = await fileUploadClient.post(`/uploadImage/${collection}/${docId}/${name}`, data);
  return response.data;
};
const uploadEventMediaItem = async ({ data, name, collection, docId }: UploadMediaDTO) => {
  const response = await fileUploadClient.post(`/uploadEventImage/${collection}/${docId}/${name}`, data);
  return response.data;
}

export interface DeleteMediaDTO {
  tableId: string;
  filePath: string;
}
const deleteMediaItem = async (data: DeleteMediaDTO) => {
  const response = await firebaseFunctionsClient.post(`/deleteImage`, data);
  return response.data;
};

const MediaService = {
  uploadMediaItem,
  uploadEventMediaItem,
  deleteMediaItem,
};

export default MediaService;
