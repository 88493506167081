import LoadingSpinner from "@/components/loading-spinner";
import TruncateText from "@/components/truncate-text";
import { getCdnImageUri } from "@/helpers/cdn.helper";
import { getFileNameFromUrl } from "@/helpers/common.helper";
import { Button, Card, CardContent } from "@ui/components";
import { Trash } from "lucide-react";

interface MediaUploadCardProps {
  path: string;
  deleteAction: React.MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
  variant?: 'simple' | 'full';
}

export default function MediaUploadCard({ path, deleteAction, isLoading, variant = 'full' }: MediaUploadCardProps) {
  const fileName = getFileNameFromUrl(path);

  return (
    <Card className='flex-1 w-full'>
      <CardContent className='h-full p-1 space-y-1 bg-tertiary'>
        <img
          src={getCdnImageUri(path)}
          alt={fileName}
          className='object-cover h-[210px] w-full rounded-md'
        />
        {variant === 'full' ? (
            <div className='flex items-center justify-between'>
              <div className='pl-2'>
                <TruncateText maxLength={12} text={fileName} />
              </div>
              <Button
                  variant='destructive'
                  size='sm'
                  className=''
                  disabled={isLoading}
                  onClick={deleteAction}
                  type="button"
              >
                {isLoading ? <LoadingSpinner /> : <Trash size={14} />}
              </Button>
            </div>
        ) : null}
      </CardContent>
    </Card>
  );
}
