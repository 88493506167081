import { renderCheck } from '@/components/form/rhf-combobox/helper';
import { SelectOption } from '@/components/form/rhf-combobox/select-options';
import { CommandGroup, CommandItem } from '@ui/components';
import { ControllerRenderProps, FieldValues, Path, PathValue } from 'react-hook-form';

export interface OptionGroup<TFieldValues> {
  label: string;
  options: SelectOption<TFieldValues>[];
}

interface GroupedOptionsProps<TFieldValues extends FieldValues> {
  options: OptionGroup<TFieldValues>[];
  field: ControllerRenderProps<TFieldValues>;
  handleSelect: (
    value: PathValue<TFieldValues, Path<TFieldValues>>,
    field: ControllerRenderProps<TFieldValues>,
  ) => void;
}

export function GroupedOptions<TFieldValues extends FieldValues>({
  options,
  field,
  handleSelect,
}: GroupedOptionsProps<TFieldValues>) {
  return options.map((group) => (
    <CommandGroup key={group.label}>
      <div className="px-2 py-1 text-xs font-medium text-muted-foreground">{group.label}</div>
      {group.options.map((option) => (
        <CommandItem
          value={option.label}
          key={option.value}
          onSelect={() => handleSelect(option.value, field)}
        >
          {renderCheck(option.value, field)}
          <div className="flex justify-between w-full gap-4">
            <span className="">{option.label}</span>
            <span className="brightness-0">{option.icon && option.icon}</span>
          </div>
        </CommandItem>
      ))}
    </CommandGroup>
  ));
}
