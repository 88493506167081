import { capitalized } from "@/helpers/common.helper";
import { AmbienceAttribute, PrivacyAttribute, TableLocation, TableType, ViewAttribute } from "@repo/types";

export const TableTypeOptions = Object.values(TableType).map((option) => ({
  label: capitalized(option),
  value: option
}))

export const TableLocationOptions = Object.values(TableLocation).map((option) => ({
  label: capitalized(option),
  value: option
}))

export const TableAmbienceOptions = Object.values(AmbienceAttribute).map((option) => ({
  label: capitalized(option),
  value: option
}))

export const TableViewOptions = Object.values(ViewAttribute).map((option) => ({
  label: capitalized(option),
  value: option
}))

export const TablePrivacyOptions = Object.values(PrivacyAttribute).map((option) => ({
  label: capitalized(option),
  value: option
}))
