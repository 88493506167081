import { invalidDisplay, policyCreateFail, policyCreateSuccess } from "@/components/toasts";
import PolicyService from "@/services/policy.service";
import { CreatePolicyRequest, Policy } from "@repo/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";

const useTablePolicyCreate = (tableId?: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Policy, Error, { restaurantId: string; tableId: string; data: CreatePolicyRequest }>({
    mutationKey: ["createTablePolicy", tableId],
    mutationFn: ({ restaurantId, tableId, data }) => PolicyService.createTablePolicy(restaurantId, tableId, data),
    onSuccess: (data) => {
      toast(policyCreateSuccess(`'${data.displays[0].title}' policy successfully added to table`));
      return queryClient.invalidateQueries({ queryKey: ["tablePolicies", tableId] });
    },
    onError: (error, { data }) => {
      if (data.displays?.length === 0) {
        toast(invalidDisplay);
        return;
      }
      console.error(error);
      toast(policyCreateFail);
    },
  });
};

export { useTablePolicyCreate };
