import MatterportService from '@/services/matterport.service';
import { useQuery } from '@tanstack/react-query';

const useMatterportTags = (modelId: string) => {
  return useQuery({
    enabled: modelId?.length > 0,
    queryKey: ['matterportTags', modelId],
    queryFn: () => MatterportService.getTagsByModelId(modelId),
    select: (data) => data?.data?.model?.mattertags,
  });
};

export { useMatterportTags };
