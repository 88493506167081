import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/components";
import { Info } from "lucide-react";
import { useState } from "react";

export default function IconWithTooltip({ text }: { text: string }) {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip open={open}>
      <TooltipTrigger
        className='cursor-default'
        onPointerDownCapture={(e) => e.stopPropagation()}
        onClick={() => setOpen((open) => !open)}
        type="button"
      >
        <div className='p-1 rounded-md hover:bg-secondary'>
          <Info size={16} />
        </div>
      </TooltipTrigger>
      <TooltipContent side='right' className='max-w-[400px]' onPointerDownOutside={() => setOpen(false)}>
        {text}
      </TooltipContent>
    </Tooltip>
  );
}
