
const MAX_IMG_WIDTH_MAP: Record<string, number> = {
	mobile: 640,
	tablet: 960,
	browser: 1920
};

const DEFAULT_MAX_IMG_WIDTH = MAX_IMG_WIDTH_MAP.tablet;


type ImageOptions = {
	fit?: 'cover' | 'contain' | 'crop' | 'scale-down' | 'pad';
	width?: number;
	height?: number;
	dpr?: 1 | 2;
};

export function getCdnImageUri(
	assetPath: string,
	options?: ImageOptions
): string {
	const cdnAssetPath = `/cdn-cgi/image/${buildOptionsString(options)}${assetPath}`;
	return new URL(cdnAssetPath, 'https://cdn.tablz.net').toString();
}

const buildOptionsString = (options?: ImageOptions) => {
	const optionStrings: string[] = [];

	const addOption = (name: string, value?: string | number) => {
		if (value) {
			optionStrings.push(`${name}=${value}`);
		}
	};

	addOption('fit', options?.fit);
	addOption('dpr', options?.dpr);
	addOption('height', options?.height);
	addOption('width', options?.width || DEFAULT_MAX_IMG_WIDTH);

	return optionStrings.join(',');
};