import { INavLink } from '@/config/nav-config';
import { Coins, Image, List, Move3D, Pencil } from 'lucide-react';

export const editPinNavConfig: INavLink[] = [
  {
    title: 'Details',
    href: 'details',
    icon: <Pencil />,
  },
  {
    title: 'Media',
    href: 'media',
    icon: <Image />,
  },
  // {
  //   title: 'Schedule',
  //   href: 'schedule',
  //   icon: <CalendarRange />,
  // },
  // {
  //   title: 'Closures',
  //   href: 'closures',
  //   icon: <CalendarX />
  // },
  {
    title: 'Policies',
    href: 'policies',
    icon: <List />,
  },
  {
    title: 'Fees',
    href: 'fees',
    icon: <Coins />,
  },
  {
    title: 'Location',
    href: 'location',
    icon: <Move3D />,
  },
];
