import { ConfirmationDialog } from '@/components/confirmation-dialog';
import RHFTextInput from '@/components/form/rhf-text-input';
import LoadingSpinner from '@/components/loading-spinner';
import Heading from '@/components/typography/heading';
import { jsDateToFullDate } from '@/helpers/datetime.helper';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import {Event, UpdateEventRequest} from '@repo/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ConditionValue } from '@repo/types';
import {
    Button,
    Calendar, Input,
    Label,
    ScrollArea,
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
    Switch,
    useToast,
} from '@ui/components';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
    useBlocker,
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import { z } from 'zod';
import {useEvents} from "@/hooks/useEvents";
import {useEventUpdate} from "@/hooks/useEventUpdate";
import RHFTextArea from "@/components/form/rhf-text-area";
import MediaUploadCard from "@/components/media-upload-card";
import FileUpload from "@/components/file-upload";

const dayMapping = {
    Monday: 'mon',
    Tuesday: 'tues',
    Wednesday: 'wed',
    Thursday: 'thurs',
    Friday: 'fri',
    Saturday: 'sat',
    Sunday: 'sun',
} as const;

type DayMappingValues = (typeof dayMapping)[keyof typeof dayMapping];

const DayOfWeekSchema = z.enum(['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun']);

// Define the ConditionValue schema
const conditionValueSchema: z.ZodType<ConditionValue> = z.lazy(() =>
    z.object({
        daysOfWeek: z.array(DayOfWeekSchema).optional(),
        dates: z.array(z.string().regex(/^[A-Za-z]+\s\d{2},\s\d{4}$/)).optional(), // Assuming date format is 'MMMM dd, yyyy'
        betweenDates: z.tuple([z.string(), z.string()]).optional(),
        betweenTimes: z.tuple([z.string(), z.string()]).optional(), // Assuming time strings like '16:00', '5:45 pm'
        not: z.lazy(() => conditionValueSchema).optional(), // Recursively define the 'not' field
    }),
);

const formSchema = z.object({
    name: z.string(),
    description: z.string(),
    condition: conditionValueSchema.optional(),
    isActive: z.boolean().optional(),
});

const triggerOptions = {
    daysOfWeek: 'Day(s)',
    dates: 'Date(s)',
};
type TriggerOptionsKeys = keyof typeof triggerOptions | undefined;

export default function UpdateEvent() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { toast } = useToast();

    const [triggerValue, setTriggerValue] = useState<TriggerOptionsKeys>();

    if (!params.restaurantSlug) {
        throw Error(`EditEventSettings requires a restaurantSlug url param`);
    }

    if (!params.eventId) {
        throw Error(`EditEventSettings requires a eventID url param`);
    }

    const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
    const eventsQuery = useEvents(restaurantQuery.data?.id || '');

    const updateEventMutation = useEventUpdate(restaurantQuery.data?.id || '');

    const currentEvent = (eventsQuery?.data as Event[]).find((event) => event.id === params.eventId);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        values: {
            name: currentEvent?.name || '',
            isActive: currentEvent?.isActive || false,
            description: currentEvent?.displays[0]?.description || '',
            condition: {
                dates: undefined,
                daysOfWeek: undefined,
                betweenDates: undefined,
                betweenTimes: undefined,
                not: undefined,
                ...(currentEvent?.condition.value || {}),
            },
        },
    });

    // Block navigating elsewhere when form has unsaved changes
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            form.formState.isDirty && currentLocation.pathname !== nextLocation.pathname,
    );

    const handleClose = () => {
        // TODO: check if form state is dirty
        const newPath = location.pathname.replace(`/${params.eventId}`, '');
        navigate(newPath);
    };

    const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (values) => {
        if (values.condition?.betweenTimes?.[0] === '' && values.condition?.betweenTimes?.[1] === '') {
            values.condition.betweenTimes = undefined;
        }
        const result = await updateEventMutation.mutateAsync({
            data: {
                id: currentEvent?.id,
                name: values.name,
                isActive: values.isActive,
                condition: {
                    value: (values.condition),
                    weight: 1,
                },
                displays: [
                    {
                        title: values.name,
                        description: values.description,
                        location: 'table',
                        icon: '',
                    },
                    {
                        title: values.name,
                        description: values.description,
                        location: 'checkout',
                        icon: '',
                    },
                    {
                        title: values.name,
                        description: values.description,
                        location: 'checkout',
                        icon: '',
                    },
                ],
            } as UpdateEventRequest
        });
        if (result) {
            toast({
                description: 'event successfully updated'
            });
        }
    };

    const handleSelectedDaysChange = (day: DayMappingValues, checked: boolean) => {
        const currentDays = form.getValues('condition.daysOfWeek') || [];
        const updatedDays = checked ? [...currentDays, day] : currentDays.filter((d) => d !== day);
        form.setValue('condition.daysOfWeek', updatedDays.length > 0 ? updatedDays : undefined, {
            shouldDirty: true,
        });
        form.setValue('condition.dates', undefined, { shouldDirty: true });
    };

    const handleSelectedDatesChange = (dates: Date[] | undefined) => {
        const formattedDates = [...(dates?.map((date) => jsDateToFullDate(date)) || [])];
        const updatedDates = formattedDates.length > 0 ? formattedDates : undefined;
        form.setValue('condition.dates', updatedDates, {
            shouldDirty: true,
        });
        if (!updatedDates) {
            form.resetField('condition.daysOfWeek');
        } else {
            form.setValue('condition.daysOfWeek', undefined, { shouldDirty: true });
        }
    };

    const getTriggerValue = (currentEvent: Event): TriggerOptionsKeys => {
        let result: TriggerOptionsKeys;
        if (currentEvent?.condition?.value?.daysOfWeek) {
            result = 'daysOfWeek';
        } else if (currentEvent?.condition?.value?.dates || currentEvent?.condition?.value?.betweenDates) {
            result = 'dates';
        }
        return result;
    };

    const conditionInputs: { [key: string]: JSX.Element | null } = {
        default: null,
        daysOfWeek: (
            <>
                <div className="flex flex-col gap-1">
                    {Object.entries(dayMapping).map(([dayName, dayValue]) => (
                        <div key={dayValue} className="flex justify-between py-2">
                            <Label htmlFor={`${dayValue}-switch`}>{dayName}</Label>
                            <Controller
                                name={`condition.daysOfWeek`}
                                control={form.control}
                                render={({ field }) => (
                                    <Switch
                                        id={`${dayValue}-switch`}
                                        checked={field.value?.includes(dayValue)}
                                        onCheckedChange={(checked) => handleSelectedDaysChange(dayValue, checked)}
                                    />
                                )}
                            />
                        </div>
                    ))}

                    <div className="flex w-full gap-2">
                        <div className="flex flex-col flex-1 gap-2">
                            <Label htmlFor="start-time">Start</Label>
                            <Controller
                                name="condition.betweenTimes.0"
                                control={form.control}
                                defaultValue=""
                                render={({ field: { value, onChange, onBlur, ref } }) => (
                                    <Input
                                        id="start-time"
                                        type="time"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        ref={ref}
                                    />
                                )}
                            />
                        </div>

                        <div className="flex flex-col flex-1 gap-2">
                            <Label htmlFor="end-time">End</Label>
                            <Controller
                                name="condition.betweenTimes.1"
                                control={form.control}
                                defaultValue=""
                                render={({ field: { value, onChange, onBlur, ref } }) => (
                                    <Input
                                        id="end-time"
                                        type="time"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        ref={ref}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </>
        ),
        dates: (
            <>
            <div className="flex justify-center w-full">
                <Controller
                    name="condition.dates"
                    control={form.control}
                    render={({ field }) => (
                        <Calendar
                            mode="multiple"
                            fromDate={new Date()}
                            selected={field.value ? field.value.map((dateString) => new Date(dateString)) : []}
                            onSelect={(dates) => handleSelectedDatesChange(dates)}
                        />
                    )}
                />
                <div className="flex w-full gap-2">
                    <div className="flex flex-col flex-1 gap-2">
                        <Label htmlFor="start-time">Start</Label>
                        <Controller
                            name="condition.betweenTimes.0"
                            control={form.control}
                            defaultValue=""
                            render={({ field: { value, onChange, onBlur, ref } }) => (
                                <Input
                                    id="start-time"
                                    type="time"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    ref={ref}
                                />
                            )}
                        />
                    </div>
                    <div className="flex flex-col flex-1 gap-2">
                        <Label htmlFor="end-time">End</Label>
                        <Controller
                            name="condition.betweenTimes.1"
                            control={form.control}
                            defaultValue=""
                            render={({ field: { value, onChange, onBlur, ref } }) => (
                                <Input
                                    id="end-time"
                                    type="time"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    ref={ref}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            </>
        ),
    };

    useEffect(() => {
        if (currentEvent) {
            setTriggerValue(getTriggerValue(currentEvent));
        }
    }, [currentEvent]);

    if (restaurantQuery.isLoading) {
        return <LoadingSpinner />;
    }

    if (!currentEvent) {
        return <div>Event not found</div>;
    }
    let path = `/images/events/${currentEvent.id}/coverImage.jpg`;
    if(import.meta.env.VITE_NODE_ENV !== 'production') {
        path = `/staging/images/events/${currentEvent.id}/coverImage.jpg`;
    }

    return (
        <>
            <div className="flex flex-col h-full">
                <header className="flex justify-between px-5 py-3 border-b">
                    <Heading>{currentEvent.name}</Heading>
                    <button onClick={handleClose}>
                        <X />
                    </button>
                </header>

                <FormProvider {...form}>
                    <ScrollArea className="flex-1">
                        <form id="edit-event-form" onSubmit={form.handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-4 px-5 py-3">
                                <FileUpload label="Media" onFilesChange={() => {}} collection={'Events'} />

                                <MediaUploadCard
                                    path={path}
                                    deleteAction={() => {}}
                                    isLoading={false}
                                    variant={'simple'}
                                />

                                <RHFTextInput label="Name" form={form} name="name" />
                                <RHFTextArea label={'Description'} form={form} name="description"  />

                                <Label htmlFor="trigger-condition">Trigger</Label>
                                <Select
                                    defaultValue={'not'}
                                    value={triggerValue}
                                    onValueChange={(value) => {
                                        setTriggerValue(value as TriggerOptionsKeys);
                                    }}
                                >
                                    <SelectTrigger id="trigger-condition">
                                        <SelectValue placeholder="Select trigger" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value={'daysOfWeek'}>{triggerOptions.daysOfWeek}</SelectItem>
                                        <SelectItem value={'dates'}>{triggerOptions.dates}</SelectItem>
                                    </SelectContent>
                                </Select>
                                {triggerValue && conditionInputs[triggerValue]}
                            </div>
                        </form>
                    </ScrollArea>
                </FormProvider>

                <footer className="p-3 border-t-[1px]">
                    <Button
                        form="edit-event-form"
                        type="submit"
                        className="w-full"
                        disabled={!form.formState.isDirty || form.formState.isSubmitting}
                    >
                        {form.formState.isSubmitting ? <LoadingSpinner /> : 'Save'}
                    </Button>
                </footer>
            </div>

            {blocker.state === 'blocked' ? (
                <ConfirmationDialog
                    open={blocker.state === 'blocked'}
                    title="You have unsaved changes!"
                    description={`Are you sure you want to leave? Your changes to the event: '${currentEvent.name}' will be lost.`}
                    cancelLabel="Cancel"
                    continueLabel="Discard Changes"
                    onContinue={() => blocker.proceed()}
                    onCancel={() => blocker.reset()}
                />
            ) : null}
        </>
    );
}
