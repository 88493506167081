import { policyUpdateFail, policyUpdateSuccess } from "@/components/toasts";
import PolicyService from "@/services/policy.service";
import { Policy, UpdatePolicyRequest } from "@repo/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@ui/components";

const useRestaurantPolicyUpdate = (id: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Policy, Error, { id: string; data: UpdatePolicyRequest }>({
    mutationKey: ["updateRestaurantPolicy", id],
    mutationFn: ({ id, data }) => PolicyService.updateRestaurantPolicy(id, data),
    onSuccess: (data) => {
      toast(policyUpdateSuccess(`'${data.displays[0]?.title || "Restaurant"}' policy successfully updated`));
      return queryClient.invalidateQueries({ queryKey: ["restaurantPolicies", id] });
    },
    onError: (error) => {
      console.error(error);
      toast(policyUpdateFail("Failed to update restaurant policy"));
    },
  });
};

export { useRestaurantPolicyUpdate };
