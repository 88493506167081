import { Input } from "@repo/ui";
import { Table } from "@tanstack/react-table";

interface DataTableFilterProps<TData> {
  table: Table<TData>;
  placeholder: string;
  columnId: string;
}

export function DataTableFilter<TData>({ table, placeholder, columnId }: DataTableFilterProps<TData>) {
  return (
    <Input
      placeholder={placeholder}
      value={(table.getColumn(columnId)?.getFilterValue() as string) ?? ""}
      onChange={(event) => table.getColumn(columnId)?.setFilterValue(event.target.value)}
      className='h-8 w-[150px] lg:w-[250px]'
    />
  );
}
