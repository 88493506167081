import { Button } from "@repo/ui";
import { cn } from "@repo/ui/lib/utils";
import { useNavigate } from "react-router-dom";

interface PageNotFoundErrorProps extends React.HTMLAttributes<HTMLDivElement> {
  minimal?: boolean;
}

export default function PageNotFoundError({
  className,
  minimal = false,
}: PageNotFoundErrorProps) {
  const navigate = useNavigate();
  return (
    <div className={cn("h-svh w-full", className)}>
      <div className="flex flex-col items-center justify-center w-full h-full gap-2 m-auto">
        {!minimal && (
          <h1 className="text-[7rem] font-bold leading-tight">404</h1>
        )}
        <span className="font-medium">Oops! - Page Not Found</span>
        <p className="text-center text-muted-foreground">
          The page you were looking for doesn't exists or has moved.
        </p>
        {!minimal && (
          <div className="flex gap-4 mt-6">
            <Button variant="outline" onClick={() => navigate(-1)}>
              Go Back
            </Button>
            <Button onClick={() => navigate("/")}>Back to Home</Button>
          </div>
        )}
      </div>
    </div>
  );
}
