import Draggable from "@/components/draggable/draggable";
import { TItem } from "@/components/draggable/item";
import { MediaContent } from "@repo/types";
import { useEffect, useState } from "react";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";

type DraggableMediaItem = TItem & MediaContent;

interface DraggableExampleProps<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  initialItems: DraggableMediaItem[];
}

export default function MediaUploads<TFieldValues extends FieldValues>({
  form,
  name,
  initialItems,
}: DraggableExampleProps<TFieldValues>) {
  const [items, setItems] = useState<DraggableMediaItem[]>([]);

  useEffect(() => {
    if (initialItems && initialItems.length > 0) {
      setItems(initialItems);
    }
  }, [initialItems]);

  const handleDragEnd = (newItems: DraggableMediaItem[]) => {
    setItems(newItems);
    form.setValue(name, newItems as PathValue<TFieldValues, Path<TFieldValues>>, { shouldDirty: true });
  };

  return <Draggable items={items} onDragEnd={handleDragEnd} />;
}
