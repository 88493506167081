import RestaurantService from '@/services/restaurant.service';
import { Restaurant } from '@repo/types';
import { useQuery } from '@tanstack/react-query';

const useRestaurantBySlug = (slug: string) => {
  return useQuery<Restaurant, Error>({
    queryKey: ['getRestaurantBySlug', slug],
    queryFn: () => RestaurantService.findOneBySlug(slug),
  });
};

export { useRestaurantBySlug };
