import { Policy } from "@repo/types";
import { useQuery } from "@tanstack/react-query";
import PolicyService from "@/services/policy.service";

const useRestaurantPolicies = (restaurantId: string) => {
  return useQuery<Policy[], Error>({
    enabled: !!restaurantId,
    queryKey: ["restaurantPolicies", restaurantId],
    queryFn: () => PolicyService.findAllRestaurantPolicies(restaurantId),
    // return only the policies that are not also attached to a table
    select: (data) => data.filter(policy => !policy.tableId)
  });
};

const useTablePolicies = (restaurantId: string, tableId?: string) => {
  return useQuery<Policy[], Error>({
    enabled: !!tableId,
    queryKey: ["tablePolicies", tableId],
    queryFn: () => PolicyService.findAllTablePolicies(restaurantId, tableId!),
  });
};

export {
  useRestaurantPolicies,
  useTablePolicies,
};
