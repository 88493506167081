import TablzLogoIcon from "@/assets/tablz-logo-icon.svg?react";
import { Link } from "react-router-dom";

export default function LogoSmall() {
  return (
    <Link to="/" className="flex items-center gap-2 p-2">
      <TablzLogoIcon className="w-6 h-6" />
    </Link>
  );
}
